/* eslint-disable simple-import-sort/imports */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as ApolloReactHooks from '@app/data/graphqlHooks'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** Scalar defined on BE - unified date format. */
  Date: string
  /** Unified date + time format */
  DateTime: string
}

export enum ActivityResult {
  ALMOST_CORRECT = 'ALMOST_CORRECT',
  CORRECT = 'CORRECT',
  NOT_INVOLVED = 'NOT_INVOLVED',
  SKIPPED = 'SKIPPED',
  WRONG = 'WRONG'
}

export type ApplicationSettings = {
  __typename?: 'ApplicationSettings'
  appLanguage: Language
}

export type ApplicationSettingsInput = {
  appLanguage?: InputMaybe<Language>
}

export type Book = {
  __typename?: 'Book'
  currentPage: Scalars['Int']
  id: Scalars['ID']
  image: ImageType
  label: Scalars['String']
  sourceUrl?: Maybe<Scalars['String']>
}

export type BugReport = {
  __typename?: 'BugReport'
  createdDate: Scalars['DateTime']
  externalId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  message?: Maybe<Scalars['String']>
  resolvedDate?: Maybe<Scalars['DateTime']>
  type: BugReportMessageType
}

export type BugReportMessageInput = {
  context: Scalars['String']
  message: Scalars['String']
  type: BugReportMessageType
}

export enum BugReportMessageType {
  BUG = 'BUG',
  SUGGESTION = 'SUGGESTION'
}

export enum CEFRLevel {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2'
}

export type Classroom = {
  __typename?: 'Classroom'
  book?: Maybe<Learning>
  code: Scalars['String']
  color?: Maybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  pictureId?: Maybe<Scalars['String']>
  studentsInClassroom: Array<Student>
  subject: Subject
  yearOfStudy: Scalars['String']
}

export type ClassroomInput = {
  bookId?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  pictureId?: InputMaybe<Scalars['String']>
  subject: Subject
}

export type ClassroomLogin = {
  __typename?: 'ClassroomLogin'
  classroomLoginCode: ClassroomLoginCode
  students: Array<UserProfile>
}

export type ClassroomLoginCode = {
  __typename?: 'ClassroomLoginCode'
  classroomId: Scalars['ID']
  classroomLoginCode: Scalars['String']
  expirationTime: Scalars['DateTime']
}

export type CompletedLearningExecution = {
  __typename?: 'CompletedLearningExecution'
  answers: Array<FlashCardAnswer>
  classRank: Scalars['Int']
  learningExecution: LearningExecution
  studentCount: Scalars['Int']
  studyTimeSeconds: Scalars['Int']
  summary?: Maybe<Scalars['String']>
  timestamp: Scalars['DateTime']
  totalSkippedCount: Scalars['Int']
}

export type ComposedFlashCard = FlashCard & {
  __typename?: 'ComposedFlashCard'
  assignedLearningFeatures: Array<LearningFeatureNew>
  flashCardAssignmentType: FlashCardAssignmentType
  flashCardResponseType: FlashCardResponseType
  id: Scalars['ID']
  learningExecutionProgress: Scalars['Int']
  learningExecutionState: LearningExecutionState
  responseLearningFeatures: Array<LearningFeatureNew>
  subjectLanguage: Language
  totalPoints: Scalars['Int']
}

export enum Device {
  MOBILE = 'MOBILE',
  PROJECTOR = 'PROJECTOR',
  TABLET = 'TABLET'
}

export enum Difficulty {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MIDDLE = 'MIDDLE'
}

export type ExecutionExitInput = {
  elapsedTimeMilliseconds: Scalars['Int']
  learningExecutionId: Scalars['ID']
}

export type FlashCard = {
  id: Scalars['ID']
  learningExecutionProgress: Scalars['Int']
  learningExecutionState: LearningExecutionState
  totalPoints: Scalars['Int']
}

export type FlashCardAnswer = {
  __typename?: 'FlashCardAnswer'
  flashCardAssignmentType?: Maybe<FlashCardAssignmentType>
  flashCardFullScreenType?: Maybe<FullScreenFlashCardType>
  flashCardId: Scalars['ID']
  flashCardResponseType?: Maybe<FlashCardResponseType>
  points: Scalars['Int']
  skipCount: Scalars['Int']
  tryCount: Scalars['Int']
  userAnswers: Array<UserAnswer>
}

export enum FlashCardAssignmentType {
  IMAGE = 'IMAGE',
  SOUND = 'SOUND',
  TEXT = 'TEXT',
  VIDEO = 'VIDEO'
}

export enum FlashCardResponseType {
  CHOICE_IMAGE = 'CHOICE_IMAGE',
  CHOICE_TEXT = 'CHOICE_TEXT',
  SCRABBLE = 'SCRABBLE',
  SOUND = 'SOUND',
  TEXT = 'TEXT',
  WORD_PICKING = 'WORD_PICKING'
}

export type FrequentMistakes = {
  __typename?: 'FrequentMistakes'
  learningFeature: LearningFeatureNew
  mistakeCount: Scalars['Int']
}

export type FullScreenFlashCard = FlashCard & {
  __typename?: 'FullScreenFlashCard'
  assignedLearningFeatures: Array<LearningFeatureNew>
  fullScreenFlashCardType: FullScreenFlashCardType
  id: Scalars['ID']
  learningExecutionProgress: Scalars['Int']
  learningExecutionState: LearningExecutionState
  responseLearningFeatures: Array<LearningFeatureNew>
  subjectLanguage: Language
  totalPoints: Scalars['Int']
}

export enum FullScreenFlashCardType {
  SHOPPING_CART = 'SHOPPING_CART',
  SOUND_TO_TEXT_CONNECTION = 'SOUND_TO_TEXT_CONNECTION',
  SWIPE_MATCH = 'SWIPE_MATCH',
  TEXT_TO_IMAGE_CONNECTION = 'TEXT_TO_IMAGE_CONNECTION',
  TEXT_TO_TEXT_CONNECTION = 'TEXT_TO_TEXT_CONNECTION',
  WHEEL_OF_FORTUNE_IMAGE = 'WHEEL_OF_FORTUNE_IMAGE',
  WHEEL_OF_FORTUNE_TEXT = 'WHEEL_OF_FORTUNE_TEXT'
}

export enum GQLWorksheetLexicalType {
  ANTONYMS = 'ANTONYMS',
  DEFINITIONS = 'DEFINITIONS',
  SYNONYMS = 'SYNONYMS',
  TRANSLATIONS = 'TRANSLATIONS'
}

export enum GQLWorksheetType {
  MATCH_ELEMENTS = 'MATCH_ELEMENTS',
  ONE_TO_MANY = 'ONE_TO_MANY',
  PUZZLE = 'PUZZLE'
}

export type GameProperties = {
  __typename?: 'GameProperties'
  coverStyle?: Maybe<Scalars['String']>
  isSoundEnabled?: Maybe<Scalars['Boolean']>
  questionCount?: Maybe<Scalars['Int']>
  secondsPerQuestion?: Maybe<Scalars['Int']>
  showQuestionInClient?: Maybe<Scalars['Boolean']>
  title?: Maybe<Scalars['String']>
  worksheetLexicalType?: Maybe<Scalars['String']>
  worksheetType?: Maybe<Scalars['String']>
}

export type GamePropertiesInput = {
  coverStyle?: InputMaybe<Scalars['String']>
  isSoundEnabled?: InputMaybe<Scalars['Boolean']>
  questionCount?: InputMaybe<Scalars['Int']>
  secondsPerQuestion?: InputMaybe<Scalars['Int']>
  showQuestionInClient?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  worksheetLexicalType?: InputMaybe<Scalars['String']>
  worksheetType?: InputMaybe<Scalars['String']>
}

export type ImageType = {
  __typename?: 'ImageType'
  default: Scalars['String']
  large?: Maybe<Scalars['String']>
  medium?: Maybe<Scalars['String']>
  small?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
}

export type InfoFlashCard = FlashCard & {
  __typename?: 'InfoFlashCard'
  id: Scalars['ID']
  informationType: InformationFlashCardType
  learningExecutionProgress: Scalars['Int']
  learningExecutionState: LearningExecutionState
  totalPoints: Scalars['Int']
}

export enum InformationFlashCardType {
  LISTENING = 'LISTENING',
  MISTAKES = 'MISTAKES',
  PROGRESS = 'PROGRESS',
  READING = 'READING',
  SPEAKING = 'SPEAKING',
  WRITING = 'WRITING'
}

export enum InteractionType {
  LISTENING = 'LISTENING',
  SPEAKING = 'SPEAKING'
}

export enum Language {
  CZECH = 'CZECH',
  ENGLISH = 'ENGLISH',
  FRENCH = 'FRENCH',
  GERMAN = 'GERMAN',
  ITALIAN = 'ITALIAN',
  SPANISH = 'SPANISH'
}

export type Learning = {
  __typename?: 'Learning'
  children?: Maybe<Array<Learning>>
  id: Scalars['ID']
  image?: Maybe<ImageType>
  imageURL?: Maybe<Scalars['String']>
  learningFeatureCount: Scalars['Int']
  learningFeatures: Array<LearningFeatureNew>
  level: CEFRLevel
  name: Scalars['String']
  sourceLanguage: Language
  subject: Subject
  type: LearningType
}

export type LearningActivitiesSearchInput = {
  activityStates: Array<LearningActivityState>
  activityTypes: Array<LearningActivityType>
  classroomIds: Array<Scalars['ID']>
  learningPlanLessonIds: Array<Scalars['ID']>
  textSearch?: InputMaybe<Scalars['String']>
  withLearningExecutions: Scalars['Boolean']
  withLearningFeatures: Scalars['Boolean']
}

export type LearningActivity = {
  __typename?: 'LearningActivity'
  devices: Array<Device>
  durations: Array<LearningActivityDuration>
  estimatedTimeInSeconds: Scalars['Int']
  executions?: Maybe<Array<LearningExecution>>
  gameProperties?: Maybe<GameProperties>
  id: Scalars['ID']
  isInShelf: Scalars['Boolean']
  learningActivityExecutionId?: Maybe<Scalars['ID']>
  learningActivityType: LearningActivityType
  learningFeatureCount: Scalars['Int']
  learningFeatureFilter: Array<LearningFeatureType>
  learningFeatures: Array<LearningFeatureNew>
  learningPlanLessonId: Scalars['ID']
  level1Name?: Maybe<Scalars['String']>
  level2Name?: Maybe<Scalars['String']>
  name: Scalars['String']
  previousActivityId?: Maybe<Scalars['ID']>
  progress?: Maybe<LearningActivityProgress>
  skills: Array<LearningActivitySkill>
  state: LearningActivityState
  subject: Subject
  tags: Array<Scalars['String']>
}

export type LearningActivityDuration = {
  __typename?: 'LearningActivityDuration'
  durationInSeconds: Scalars['Int']
  isActive: Scalars['Boolean']
}

export type LearningActivityDurationInput = {
  durationInSeconds: Scalars['Int']
  isActive: Scalars['Boolean']
}

export type LearningActivityInput = {
  deadline?: InputMaybe<Scalars['DateTime']>
  durations?: InputMaybe<Array<LearningActivityDurationInput>>
  gameProperties?: InputMaybe<GamePropertiesInput>
  isInShelf: Scalars['Boolean']
  learningActivitiesOrder?: InputMaybe<Array<Scalars['ID']>>
  learningActivityType?: InputMaybe<LearningActivityType>
  learningFeatureFilter?: InputMaybe<Array<LearningFeatureType>>
  learningFeatures?: InputMaybe<Array<Scalars['ID']>>
  name?: InputMaybe<Scalars['String']>
  skills?: InputMaybe<Array<LearningActivitySkillInput>>
  tags?: InputMaybe<Array<Scalars['String']>>
}

export type LearningActivityParticipantResult = {
  __typename?: 'LearningActivityParticipantResult'
  answers: Array<FlashCardAnswer>
  correctAnswers?: Maybe<Scalars['Int']>
  interactions?: Maybe<Scalars['Int']>
  interventionNeeded: Scalars['Boolean']
  learningActivityId: Scalars['ID']
  participant: Participant
  points?: Maybe<Scalars['Int']>
  progress?: Maybe<Scalars['Int']>
  successMeasure?: Maybe<Scalars['Int']>
  totalAnswers?: Maybe<Scalars['Int']>
}

export type LearningActivityProgress = {
  __typename?: 'LearningActivityProgress'
  deadline?: Maybe<Scalars['DateTime']>
  progress?: Maybe<Scalars['Int']>
  total?: Maybe<Scalars['Int']>
  type?: Maybe<LearningActivityProgressType>
}

export type LearningActivityProgressInput = {
  deadline?: InputMaybe<Scalars['DateTime']>
  progress?: InputMaybe<Scalars['Int']>
  total?: InputMaybe<Scalars['Int']>
  type?: InputMaybe<LearningActivityProgressType>
}

export enum LearningActivityProgressType {
  COUNT = 'COUNT',
  PERCENTAGE = 'PERCENTAGE',
  TIME_IN_SECONDS = 'TIME_IN_SECONDS'
}

export type LearningActivityReportProgress = {
  __typename?: 'LearningActivityReportProgress'
  interventionNeeded: Scalars['Boolean']
  progress: Scalars['Int']
  progressChange: ProgressChange
  total: Scalars['Int']
}

export type LearningActivityResult = {
  __typename?: 'LearningActivityResult'
  frequentMistakes: Array<FrequentMistakes>
  learningActivityId: Scalars['ID']
  participantResults: Array<LearningActivityParticipantResult>
  skillResults: Array<LearningActivitySkillResult>
  timestamp: Scalars['DateTime']
}

export type LearningActivitySkill = {
  __typename?: 'LearningActivitySkill'
  isActive: Scalars['Boolean']
  skill: Skill
}

export type LearningActivitySkillInput = {
  isActive: Scalars['Boolean']
  skill: Skill
}

export type LearningActivitySkillResult = {
  __typename?: 'LearningActivitySkillResult'
  skill: UserStatisticsSkills
  successMeasure: Scalars['Int']
}

export enum LearningActivityState {
  EXPIRED = 'EXPIRED',
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING = 'WAITING'
}

export enum LearningActivityType {
  FLIPCARDS = 'FLIPCARDS',
  HOMEWORK = 'HOMEWORK',
  HUNTWORDS = 'HUNTWORDS',
  PEXESO = 'PEXESO',
  QUIZ = 'QUIZ',
  SELF_STUDY = 'SELF_STUDY',
  TEST = 'TEST',
  WHEEL = 'WHEEL',
  WORKSHEETS = 'WORKSHEETS'
}

export type LearningAssignment = {
  __typename?: 'LearningAssignment'
  assignedByTeacher?: Maybe<Scalars['Boolean']>
  averageSuccessMeasure?: Maybe<Scalars['Int']>
  difficulty: Difficulty
  endDateAndTime?: Maybe<Scalars['DateTime']>
  executions: Array<LearningExecution>
  id: Scalars['ID']
  image?: Maybe<ImageType>
  imageURL?: Maybe<Scalars['String']>
  learningFeatureCount: Scalars['Int']
  learningFeatures: Array<LearningFeatureNew>
  learnings: Array<Learning>
  level: CEFRLevel
  name: Scalars['String']
  notes?: Maybe<Scalars['String']>
  skills: Array<Skill>
  startDateAndTime: Scalars['DateTime']
  state: LearningAssignmentState
  studentsCount?: Maybe<Scalars['Int']>
  subject: Subject
  submissionCount?: Maybe<Scalars['Int']>
  timeLimitSeconds?: Maybe<Scalars['Int']>
  type: LearningAssignmentType
}

export type LearningAssignmentInput = {
  classroomId: Scalars['ID']
  endDateTime?: InputMaybe<Scalars['DateTime']>
  learningActivityId?: InputMaybe<Scalars['ID']>
  learningIds: Array<Scalars['ID']>
  name: Scalars['String']
  skills?: InputMaybe<Array<Skill>>
  state: LearningAssignmentState
  studentIds: Array<Scalars['ID']>
  subject: Subject
  timeLimitSeconds?: InputMaybe<Scalars['Int']>
  type: LearningAssignmentType
}

export enum LearningAssignmentState {
  CONCEPT = 'CONCEPT',
  DELETED = 'DELETED',
  EXPIRED = 'EXPIRED',
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS'
}

export enum LearningAssignmentType {
  EXERCISE = 'EXERCISE',
  HOMEWORK = 'HOMEWORK',
  LEARNING_PATH_LESSON_EXERCISE = 'LEARNING_PATH_LESSON_EXERCISE',
  PERSONALIZED_REPETITION = 'PERSONALIZED_REPETITION',
  REPETITION = 'REPETITION',
  STUDENT_LIBRARY_EXERCISE = 'STUDENT_LIBRARY_EXERCISE',
  TEST = 'TEST'
}

export type LearningExecution = {
  __typename?: 'LearningExecution'
  id: Scalars['ID']
  learningAssignment: LearningAssignment
  name: Scalars['String']
  progress: Scalars['Int']
  remainingTimeSeconds?: Maybe<Scalars['Int']>
  state: LearningExecutionState
  student: Student
  successMeasure: Scalars['Int']
}

export type LearningExecutionReport = {
  __typename?: 'LearningExecutionReport'
  executions: Array<CompletedLearningExecution>
  learningActivityType: LearningActivityType
  progress: LearningActivityReportProgress
}

export enum LearningExecutionState {
  DELETED = 'DELETED',
  EXPIRED = 'EXPIRED',
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING = 'WAITING'
}

export type LearningExecutionStatistics = {
  __typename?: 'LearningExecutionStatistics'
  learningFeatureCount: Scalars['Int']
  progress: Scalars['Int']
  successMeasure: Scalars['Int']
  successfulLearningFeatureCount: Scalars['Int']
  totalSkippedCount: Scalars['Int']
}

export type LearningExecutionStatisticsInput = {
  endDateTime: Scalars['DateTime']
  learningExecutionId: Scalars['ID']
  learningTimeSeconds: Scalars['Int']
}

export type LearningFeatureDefinition = {
  __typename?: 'LearningFeatureDefinition'
  value: Scalars['String']
}

export type LearningFeatureLexicalInput = {
  cefrLevels: Array<CEFRLevel>
  ids: Array<Scalars['ID']>
  type: LearningFeatureLexicalType
}

export type LearningFeatureLexicalItem = {
  __typename?: 'LearningFeatureLexicalItem'
  cefrLevel: CEFRLevel
  feature: LearningFeatureLexicalUnion
  id: Scalars['ID']
  type: LearningFeatureLexicalType
}

export enum LearningFeatureLexicalType {
  ANTONYM = 'ANTONYM',
  DEFINITION = 'DEFINITION',
  SYNONYM = 'SYNONYM'
}

export type LearningFeatureLexicalUnion = LearningFeatureDefinition | LearningFeatureNew

export type LearningFeatureNew = {
  __typename?: 'LearningFeatureNew'
  hasAntonyms: Scalars['Boolean']
  hasBeenSeen: Scalars['Boolean']
  hasDefinitions: Scalars['Boolean']
  hasSynonyms: Scalars['Boolean']
  id: Scalars['ID']
  images: Array<ImageType>
  level?: Maybe<CEFRLevel>
  origin: LearningFeatureNewOrigin
  sourceLanguage: Language
  sourceLanguageText: Scalars['String']
  sourceSoundUrls: Array<Scalars['String']>
  sourceVideoUrls: Array<Scalars['String']>
  targetLanguage: Language
  targetLanguageText: Scalars['String']
  targetSoundUrls: Array<Scalars['String']>
  targetVideoUrls: Array<Scalars['String']>
  type: LearningFeatureType
}

export enum LearningFeatureNewOrigin {
  BOOK = 'BOOK',
  BOOK_DISABLED = 'BOOK_DISABLED',
  CATALOGUE = 'CATALOGUE',
  USER = 'USER'
}

export enum LearningFeatureState {
  CONFIRMED = 'CONFIRMED',
  CRITICAL = 'CRITICAL',
  MASTERED = 'MASTERED',
  PROGRESSING = 'PROGRESSING',
  REVISED = 'REVISED'
}

export enum LearningFeatureType {
  MULTIWORD_EXPRESSION = 'MULTIWORD_EXPRESSION',
  PHRASE = 'PHRASE',
  SENTENCE = 'SENTENCE',
  WORD = 'WORD'
}

export type LearningPathNode = {
  __typename?: 'LearningPathNode'
  assignmentIndex?: Maybe<Scalars['Int']>
  assignmentState: LearningPathNodeState
  assignmentType?: Maybe<LearningAssignmentType>
  deadline?: Maybe<Scalars['DateTime']>
  executionId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  isPersistent: Scalars['Boolean']
  name: Scalars['String']
  progress: LearningPathNodeProgress
  subject: Subject
  type: LearningPathNodeType
}

export type LearningPathNodeProgress = {
  __typename?: 'LearningPathNodeProgress'
  progress: Scalars['Int']
  total: Scalars['Int']
}

export enum LearningPathNodeState {
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING = 'WAITING'
}

export enum LearningPathNodeType {
  ASSIGNMENT = 'ASSIGNMENT',
  BLOCKER = 'BLOCKER',
  LESSON = 'LESSON',
  UNIT = 'UNIT'
}

export type LearningPathResponse = {
  __typename?: 'LearningPathResponse'
  classroomId: Scalars['ID']
  currentNode: Scalars['ID']
  nextNode?: Maybe<Scalars['ID']>
  nodes: Array<LearningPathNode>
  previousNode?: Maybe<Scalars['ID']>
  studentId: Scalars['ID']
}

export type LearningPlanLesson = {
  __typename?: 'LearningPlanLesson'
  books?: Maybe<Array<Book>>
  content?: Maybe<Array<LearningFeatureNew>>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isCurrent: Scalars['Boolean']
  isUserOwned?: Maybe<Scalars['Boolean']>
  learningActivities?: Maybe<Array<LearningActivity>>
  level1Name?: Maybe<Scalars['String']>
  level2Name?: Maybe<Scalars['String']>
  order1: Scalars['Int']
  previousLessonId?: Maybe<Scalars['ID']>
  userContent?: Maybe<Array<LearningFeatureNew>>
}

export type LearningPlanLessonInput = {
  content?: InputMaybe<Array<Scalars['ID']>>
  description?: InputMaybe<Scalars['String']>
  level1Name?: InputMaybe<Scalars['String']>
  level2Name: Scalars['String']
  previousLessonId?: InputMaybe<Scalars['ID']>
}

export enum LearningType {
  CATEGORY = 'CATEGORY',
  LESSON = 'LESSON',
  LEVEL = 'LEVEL',
  PACKAGE = 'PACKAGE',
  PROGRAM = 'PROGRAM',
  SUBJECT = 'SUBJECT',
  SUBPACKAGE = 'SUBPACKAGE',
  UNIT = 'UNIT'
}

export type LoginToken = {
  __typename?: 'LoginToken'
  accessToken?: Maybe<Scalars['String']>
  classroomLoginCode?: Maybe<ClassroomLoginCode>
  refreshToken?: Maybe<Scalars['String']>
  studentId: Scalars['ID']
}

export type Mutation = {
  __typename?: 'Mutation'
  addMeToClassroom: Scalars['Boolean']
  addUserLearningFeature: LearningFeatureNew
  addUserRole: Scalars['Boolean']
  answerQuizQuestion: Scalars['Boolean']
  bugReport: Scalars['ID']
  confirmReleaseNotes: Scalars['Boolean']
  createClassroom: Classroom
  createLearningActivity: LearningActivity
  createLearningPlanLesson: LearningPlanLesson
  deleteClassroom: Scalars['Boolean']
  deleteLearningActivity: Scalars['Boolean']
  deleteLearningPlanLesson: Scalars['Boolean']
  deleteUserLearningFeature: Scalars['Boolean']
  editClassroom: Classroom
  joinQuizGame: QuizGame
  leaveQuizGame: Scalars['Boolean']
  nextQuizQuestion: Scalars['Boolean']
  processExecutionExit: Scalars['Boolean']
  processLearningExecutionStatistics: LearningExecutionStatistics
  processStudentInteraction: ResponseInteraction
  removeStudentFromClass: Scalars['Boolean']
  reportBugInLearningFeature: Scalars['ID']
  startLearningActivity: LearningActivity
  startLearningPathNodeExecution: Scalars['ID']
  startQuizGame: QuizGame
  startStudentLibrarySubPackage: Scalars['ID']
  stopLearningActivity: LearningActivity
  updateAppSettings: ApplicationSettings
  updateLearningActivity: LearningActivity
  updateLearningPlanLesson: LearningPlanLesson
  updateStudentLibrarySubPackage: Scalars['Boolean']
  updateStudentProfile: Student
  updateTutorialProgress: TutorialProgress
  updateUserLearningFeature: LearningFeatureNew
  updateUserProfile: UserProfile
  userBulkRegistration: UserBulkRegistrationEnvelopeResponse
  userBulkVerification: Array<UserBulkRegistrationResponse>
}

export type MutationAddMeToClassroomArgs = {
  classroomCode: Scalars['String']
  classroomId: Scalars['ID']
}

export type MutationAddUserLearningFeatureArgs = {
  userLearningFeature?: InputMaybe<UserLearningFeatureInput>
}

export type MutationAddUserRoleArgs = {
  userRole: UserRole
}

export type MutationAnswerQuizQuestionArgs = {
  answer: Scalars['Int']
  questionId: Scalars['ID']
  quizId: Scalars['ID']
  timestamp?: InputMaybe<Scalars['DateTime']>
}

export type MutationBugReportArgs = {
  message: BugReportMessageInput
}

export type MutationConfirmReleaseNotesArgs = {
  id: Scalars['ID']
}

export type MutationCreateClassroomArgs = {
  classroomInput: ClassroomInput
}

export type MutationCreateLearningActivityArgs = {
  learningActivityInput: LearningActivityInput
  learningPlanLessonId: Scalars['ID']
}

export type MutationCreateLearningPlanLessonArgs = {
  classroomId: Scalars['ID']
  learningPlanLessonInput: LearningPlanLessonInput
}

export type MutationDeleteClassroomArgs = {
  classroomId: Scalars['ID']
}

export type MutationDeleteLearningActivityArgs = {
  learningActivitiesOrder: Array<Scalars['ID']>
  learningActivityId: Scalars['ID']
}

export type MutationDeleteLearningPlanLessonArgs = {
  learningPlanLessonId: Scalars['ID']
}

export type MutationDeleteUserLearningFeatureArgs = {
  learningFeatureId: Scalars['ID']
}

export type MutationEditClassroomArgs = {
  classroomId: Scalars['ID']
  classroomInput: ClassroomInput
}

export type MutationJoinQuizGameArgs = {
  code: Scalars['String']
}

export type MutationLeaveQuizGameArgs = {
  quizId: Scalars['ID']
}

export type MutationNextQuizQuestionArgs = {
  quizId: Scalars['ID']
}

export type MutationProcessExecutionExitArgs = {
  exerciseExitInput: ExecutionExitInput
}

export type MutationProcessLearningExecutionStatisticsArgs = {
  learningExecutionStatisticsInput: LearningExecutionStatisticsInput
}

export type MutationProcessStudentInteractionArgs = {
  studentInteractionInput: StudentInteractionInput
}

export type MutationRemoveStudentFromClassArgs = {
  classroomId: Scalars['ID']
  studentId: Scalars['ID']
}

export type MutationReportBugInLearningFeatureArgs = {
  appVersion: Scalars['String']
  bugDescription: Scalars['String']
  flashCardAssignmentType?: InputMaybe<FlashCardAssignmentType>
  flashCardFullScreenType?: InputMaybe<FullScreenFlashCardType>
  flashCardResponseType?: InputMaybe<FlashCardResponseType>
  learningFeatureId?: InputMaybe<Scalars['ID']>
  logMessage?: InputMaybe<Scalars['String']>
  os: Scalars['String']
  userId: Scalars['ID']
}

export type MutationStartLearningActivityArgs = {
  learningActivityId: Scalars['ID']
}

export type MutationStartLearningPathNodeExecutionArgs = {
  input: StartLearningPathNodeExecutionInput
}

export type MutationStartQuizGameArgs = {
  quizGameInput: QuizGameInput
}

export type MutationStartStudentLibrarySubPackageArgs = {
  level: StudentLibraryRankLevel
  subPackageId: Scalars['ID']
}

export type MutationStopLearningActivityArgs = {
  learningActivityId: Scalars['ID']
}

export type MutationUpdateAppSettingsArgs = {
  applicationSettings?: InputMaybe<ApplicationSettingsInput>
}

export type MutationUpdateLearningActivityArgs = {
  learningActivityId: Scalars['ID']
  learningActivityInput: LearningActivityInput
}

export type MutationUpdateLearningPlanLessonArgs = {
  learningPlanLessonId: Scalars['ID']
  learningPlanLessonInput: LearningPlanLessonInput
}

export type MutationUpdateStudentLibrarySubPackageArgs = {
  input: StudentLibrarySubPackageInput
  subPackageId: Scalars['ID']
}

export type MutationUpdateStudentProfileArgs = {
  studentId: Scalars['ID']
  userProfile: UserProfileInput
}

export type MutationUpdateTutorialProgressArgs = {
  isSkipped: Scalars['Boolean']
  tutorialStepCompleted: Scalars['Int']
}

export type MutationUpdateUserLearningFeatureArgs = {
  learningFeatureId: Scalars['ID']
  userLearningFeature?: InputMaybe<UserLearningFeatureInput>
}

export type MutationUpdateUserProfileArgs = {
  userProfile?: InputMaybe<UserProfileInput>
}

export type MutationUserBulkRegistrationArgs = {
  classroomId: Scalars['ID']
  language: Language
  users: Array<UserBulkRegistration>
}

export type MutationUserBulkVerificationArgs = {
  classroomId: Scalars['ID']
  users: Array<UserBulkRegistration>
}

export type NextFlashCardInput = {
  buffered?: InputMaybe<Scalars['Boolean']>
  interactionTypes: Array<InteractionType>
  learningExecutionId: Scalars['ID']
  supportedFlashCardAssignmentTypes: Array<FlashCardAssignmentType>
  supportedFlashCardResponseTypes: Array<FlashCardResponseType>
  supportedFullScreenFlashCardTypes: Array<FullScreenFlashCardType>
}

export type PageableInput = {
  count: Scalars['Int']
  from: Scalars['Int']
}

export enum PartOfSpeech {
  ADJECTIVE = 'ADJECTIVE',
  ADVERB = 'ADVERB',
  NOUN = 'NOUN',
  PRONOUN = 'PRONOUN',
  VERB = 'VERB'
}

export type Participant = Student | Team

export enum ProgressChange {
  DECREASE = 'DECREASE',
  INCREASE = 'INCREASE',
  NO_CHANGE = 'NO_CHANGE'
}

export type PronunciationInput = {
  accuracyScore: Scalars['Float']
  completenessScore: Scalars['Float']
  fluencyScore: Scalars['Float']
  pronScore: Scalars['Float']
  prosodyScore: Scalars['Float']
}

export type Query = {
  __typename?: 'Query'
  books: Array<Learning>
  bugReports: Array<BugReport>
  classroom: Classroom
  classroomHomeworks: Array<LearningActivity>
  classroomLoginCode: ClassroomLoginCode
  classroomPerformanceReport: Array<StudentPerformanceReportEnvelope>
  classrooms: Array<Classroom>
  completedLearningExecution: CompletedLearningExecution
  learning?: Maybe<Learning>
  learningActivity: LearningActivity
  learningActivityResults: Array<LearningActivityResult>
  learningFeatureLexicon: Array<LearningFeatureLexicalItem>
  learningPath: LearningPathResponse
  learningPlanLessons: Array<LearningPlanLesson>
  loginStudent: LoginToken
  nextFlashCard: FlashCard
  quizGame: QuizGame
  releaseNotes: Array<ReleaseNotes>
  searchDictionaryLearningFeature: Array<LearningFeatureNew>
  searchLearningActivities: Array<LearningActivity>
  searchLearningFeature: Array<LearningFeatureNew>
  studentAppSupportedVersion: SupportedVersion
  studentDetail: StudentDetail
  studentInfo: StudentInfo
  studentLibraryDetail: StudentLibrarySubPackage
  studentLibraryOverview: StudentLibraryOverview
  studentPerformanceDetailReport: StudentPerformanceReportEnvelope
  studentRanks: Array<StudentRank>
  students: ClassroomLogin
  subjects: Array<Subject>
  tasks: Array<LearningExecution>
  tutorialProgress: TutorialProgress
  userProfile: UserProfile
  worksheetsAllowedTypes: Array<WorksheetAllowedType>
  worksheetsForActivity: Array<WorksheetPdf>
}

export type QueryBooksArgs = {
  subject: Subject
}

export type QueryBugReportsArgs = {
  page: PageableInput
}

export type QueryClassroomArgs = {
  classroomId: Scalars['ID']
}

export type QueryClassroomHomeworksArgs = {
  classroomId: Scalars['ID']
}

export type QueryClassroomLoginCodeArgs = {
  classroomId: Scalars['ID']
}

export type QueryClassroomPerformanceReportArgs = {
  classroomId: Scalars['ID']
}

export type QueryCompletedLearningExecutionArgs = {
  learningExecutionId: Scalars['ID']
}

export type QueryLearningArgs = {
  learningId: Scalars['ID']
}

export type QueryLearningActivityArgs = {
  learningActivityId: Scalars['ID']
}

export type QueryLearningActivityResultsArgs = {
  learningActivityId: Scalars['ID']
}

export type QueryLearningFeatureLexiconArgs = {
  input: LearningFeatureLexicalInput
}

export type QueryLearningPathArgs = {
  classroomId: Scalars['ID']
  learningPlanUnitId?: InputMaybe<Scalars['ID']>
}

export type QueryLearningPlanLessonsArgs = {
  classroomId: Scalars['ID']
  learningPlanLessonIds?: InputMaybe<Array<Scalars['ID']>>
  withDetail?: InputMaybe<Scalars['Boolean']>
  withLearningFeatures?: InputMaybe<Scalars['Boolean']>
}

export type QueryLoginStudentArgs = {
  classroomLoginCode: Scalars['String']
  studentId: Scalars['ID']
}

export type QueryNextFlashCardArgs = {
  nextFlashCardInput: NextFlashCardInput
}

export type QueryQuizGameArgs = {
  quizId: Scalars['ID']
}

export type QueryReleaseNotesArgs = {
  input: ReleaseNotesInput
  page: PageableInput
}

export type QuerySearchDictionaryLearningFeatureArgs = {
  filter?: InputMaybe<SearchDictionaryFilterInput>
  page: PageableInput
  searchLearningFeatureInput: SearchDictionaryLearningFeatureInput
}

export type QuerySearchLearningActivitiesArgs = {
  page: PageableInput
  parameters: LearningActivitiesSearchInput
}

export type QuerySearchLearningFeatureArgs = {
  searchLearningFeatureInput: SearchLearningFeatureInput
}

export type QueryStudentDetailArgs = {
  studentId: Scalars['ID']
}

export type QueryStudentLibraryDetailArgs = {
  subPackageId: Scalars['ID']
}

export type QueryStudentLibraryOverviewArgs = {
  subject?: InputMaybe<Subject>
}

export type QueryStudentPerformanceDetailReportArgs = {
  classroomId: Scalars['ID']
  page?: InputMaybe<PageableInput>
  studentId: Scalars['ID']
  type?: InputMaybe<LearningActivityType>
}

export type QueryStudentRanksArgs = {
  classroomId: Scalars['ID']
  timeView?: InputMaybe<TimeView>
}

export type QueryStudentsArgs = {
  classroomLoginCode: Scalars['String']
}

export type QueryTasksArgs = {
  classroomId?: InputMaybe<Scalars['ID']>
  isActive: Scalars['Boolean']
  page?: InputMaybe<PageableInput>
}

export type QueryWorksheetsForActivityArgs = {
  learningActivityId: Scalars['ID']
}

export enum QuizAssignmentType {
  IMAGE = 'IMAGE',
  SOUND = 'SOUND',
  TEXT = 'TEXT',
  VIDEO = 'VIDEO'
}

export type QuizGame = {
  __typename?: 'QuizGame'
  code: Scalars['String']
  connectedPlayers: Array<Maybe<QuizGamePlayer>>
  countdownInSeconds: Scalars['Int']
  currentQuestion?: Maybe<QuizGameQuestion>
  gameState: QuizGameState
  id: Scalars['ID']
  name: Scalars['String']
  numOfQuestions: Scalars['Int']
  secondsPerQuestion: Scalars['Int']
  showQuestionInClient: Scalars['Boolean']
}

export type QuizGameInput = {
  secondsPerQuestion: Scalars['Int']
  showQuestionInClient: Scalars['Boolean']
  socialGameId: Scalars['ID']
}

export type QuizGamePlayer = {
  __typename?: 'QuizGamePlayer'
  firstName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
  name: Scalars['String']
  newPoints: Scalars['Int']
  newPosition: Scalars['Int']
  oldPosition: Scalars['Int']
  pictureUrl?: Maybe<Scalars['String']>
  totalPoints: Scalars['Int']
}

export type QuizGameQuestion = {
  __typename?: 'QuizGameQuestion'
  assignment: QuizGameQuestionItem
  assignmentType: QuizAssignmentType
  hasNext: Scalars['Boolean']
  id: Scalars['ID']
  index: Scalars['Int']
  numOfAnswers: Scalars['Int']
  questionResult: QuizGameQuestionResult
  responses: Array<QuizGameQuestionItem>
}

export type QuizGameQuestionItem = {
  __typename?: 'QuizGameQuestionItem'
  id: Scalars['ID']
  images?: Maybe<ImageType>
  language: Language
  soundURL?: Maybe<Scalars['String']>
  text: Scalars['String']
  videoURL?: Maybe<Scalars['String']>
}

export type QuizGameQuestionResult = {
  __typename?: 'QuizGameQuestionResult'
  answerDistribution: Array<Scalars['Int']>
  correctResponseId: Scalars['ID']
}

export enum QuizGameState {
  ASSIGNMENT_COUNT_DOWN = 'ASSIGNMENT_COUNT_DOWN',
  ASSIGNMENT_FINISHED = 'ASSIGNMENT_FINISHED',
  ASSIGNMENT_IN_PROGRESS = 'ASSIGNMENT_IN_PROGRESS',
  EXITED = 'EXITED',
  FINISHED = 'FINISHED',
  WAITING_FOR_PLAYERS = 'WAITING_FOR_PLAYERS'
}

export type ReleaseNotes = {
  __typename?: 'ReleaseNotes'
  id: Scalars['ID']
  url: Scalars['String']
}

export type ReleaseNotesInput = {
  language: Language
  userRole: UserRole
}

export type ResponseInput = {
  imageURLOfResponse?: InputMaybe<Scalars['String']>
  pronunciation?: InputMaybe<PronunciationInput>
  soundURLOfResponse?: InputMaybe<Scalars['String']>
  sourceLanguage: Language
  targetLanguage: Language
  textOfResponse: Scalars['String']
}

export type ResponseInteraction = {
  __typename?: 'ResponseInteraction'
  activityResult: ActivityResult
  assignedLearningFeatureId: Scalars['ID']
  correctResponse: LearningFeatureNew
  isSkipped: Scalars['Boolean']
  learningExecutionProgress: Scalars['Int']
  learningExecutionState: LearningExecutionState
  learningFeatureState: LearningFeatureState
  newPoints: Scalars['Int']
  reactionTime: Scalars['Int']
  successMeasure: Scalars['Int']
  totalPoints: Scalars['Int']
  tryCount: Scalars['Int']
}

export type ResponseInteractionReactionTimeArgs = {
  unit?: InputMaybe<TimeUnits>
}

export type SearchDictionaryFilterInput = {
  expressionType?: InputMaybe<Array<LearningFeatureType>>
  isUserOwned?: InputMaybe<Scalars['Boolean']>
  level?: InputMaybe<Array<CEFRLevel>>
}

export type SearchDictionaryLearningFeatureInput = {
  sourceLanguage: Language
  targetLanguage: Language
  text: Scalars['String']
}

export type SearchLearningFeatureInput = {
  size?: InputMaybe<Scalars['Int']>
  sourceLanguage: Language
  targetLanguage: Language
  text: Scalars['String']
}

export enum Skill {
  READING = 'READING',
  SPEAKING = 'SPEAKING',
  WRITING = 'WRITING'
}

export type StartLearningPathNodeExecutionInput = {
  id: Scalars['ID']
  type: LearningPathNodeType
}

export type Student = {
  __typename?: 'Student'
  applicationSettings: ApplicationSettings
  classrooms: Array<Classroom>
  studentDetail: StudentDetail
  studentLastLogin?: Maybe<Scalars['DateTime']>
  userProfile: UserProfile
}

export type StudentDetail = {
  __typename?: 'StudentDetail'
  exerciseExecutions: Array<LearningExecution>
  listeningProgress: Scalars['Int']
  notes?: Maybe<Scalars['String']>
  overallSuccessMeasure: Scalars['Int']
  readingProgress: Scalars['Int']
  speakingProgress: Scalars['Int']
  testExecutions: Array<LearningExecution>
  writingProgress: Scalars['Int']
}

export type StudentInfo = {
  __typename?: 'StudentInfo'
  allLearning: Array<Learning>
  selectedLearning?: Maybe<Learning>
  student: Student
}

export type StudentInteractionInput = {
  assignedLearningFeatureId: Scalars['ID']
  flashCardAssignmentType?: InputMaybe<FlashCardAssignmentType>
  flashCardFullScreenType?: InputMaybe<FullScreenFlashCardType>
  flashCardId: Scalars['ID']
  flashCardResponseType?: InputMaybe<FlashCardResponseType>
  isSkipped: Scalars['Boolean']
  learningExecutionId: Scalars['ID']
  reactionTimeMilliseconds: Scalars['Int']
  responseInput: ResponseInput
  totalTimeMilliseconds: Scalars['Int']
  tryCount: Scalars['Int']
}

export enum StudentLibraryImageBackground {
  BEIGE = 'BEIGE',
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  LIGHT_BLUE = 'LIGHT_BLUE',
  MINT = 'MINT',
  ORANGE = 'ORANGE',
  PINK = 'PINK',
  PURPLE = 'PURPLE',
  SALMON = 'SALMON',
  YELLOW = 'YELLOW'
}

export type StudentLibraryOverview = {
  __typename?: 'StudentLibraryOverview'
  inProgress: Array<StudentLibrarySubPackageOverview>
  packages: Array<StudentLibraryPackageOverview>
}

export type StudentLibraryPackageOverview = {
  __typename?: 'StudentLibraryPackageOverview'
  id: Scalars['ID']
  name: Scalars['String']
  subPackages: Array<Maybe<StudentLibrarySubPackageOverview>>
}

export enum StudentLibraryRankLevel {
  LEGEND = 'LEGEND',
  PRO = 'PRO',
  ROOKIE = 'ROOKIE'
}

export type StudentLibrarySubPackage = {
  __typename?: 'StudentLibrarySubPackage'
  id: Scalars['ID']
  image: ImageType
  imageBackground: StudentLibraryImageBackground
  isFavorite: Scalars['Boolean']
  levels: Array<StudentLibrarySubPackageLevel>
  name: Scalars['String']
  packageId: Scalars['ID']
  packageName: Scalars['String']
}

export enum StudentLibrarySubPackageCategory {
  QUESTIONS = 'QUESTIONS',
  SENTENCES = 'SENTENCES',
  VOCABULARY = 'VOCABULARY'
}

export type StudentLibrarySubPackageInput = {
  isFavorite: Scalars['Boolean']
}

export type StudentLibrarySubPackageLevel = {
  __typename?: 'StudentLibrarySubPackageLevel'
  answers?: Maybe<Array<FlashCardAnswer>>
  category: Array<StudentLibrarySubPackageCategory>
  estimatedDurationSeconds: Scalars['Int']
  features: Array<LearningFeatureNew>
  finishedDurationSeconds?: Maybe<Scalars['Int']>
  isEnabled: Scalars['Boolean']
  isStarted: Scalars['Boolean']
  level: StudentLibraryRankLevel
  progress?: Maybe<Scalars['Int']>
  remainingDurationSeconds?: Maybe<Scalars['Int']>
  subcategory: Array<Scalars['String']>
  successMeasure?: Maybe<Scalars['Int']>
}

export type StudentLibrarySubPackageOverview = {
  __typename?: 'StudentLibrarySubPackageOverview'
  currentProgress?: Maybe<Scalars['Int']>
  currentSuccessMeasure?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  image: ImageType
  imageBackground: StudentLibraryImageBackground
  isEnabled: Scalars['Boolean']
  isFavorite: Scalars['Boolean']
  isStarted: Scalars['Boolean']
  name: Scalars['String']
}

export type StudentPerformanceReport = {
  __typename?: 'StudentPerformanceReport'
  homeworks?: Maybe<LearningExecutionReport>
  quizzes?: Maybe<LearningExecutionReport>
  selfStudies?: Maybe<LearningExecutionReport>
  student: Student
  studentSkillProgress: StudentSkillProgress
  summary: Scalars['String']
  tests?: Maybe<LearningExecutionReport>
}

export type StudentPerformanceReportEnvelope = {
  __typename?: 'StudentPerformanceReportEnvelope'
  monthly: StudentPerformanceReport
  weekly: StudentPerformanceReport
}

export type StudentRank = {
  __typename?: 'StudentRank'
  currentStudent: Scalars['Boolean']
  id: Scalars['ID']
  points: Scalars['Int']
  student: Student
}

export type StudentSkillProgress = {
  __typename?: 'StudentSkillProgress'
  listeningProgress: Scalars['Int']
  overallProgress: Scalars['Int']
  readingProgress: Scalars['Int']
  speakingProgress: Scalars['Int']
  writingProgress: Scalars['Int']
}

export enum Subject {
  CZECH = 'CZECH',
  ENGLISH = 'ENGLISH',
  FRENCH = 'FRENCH',
  GERMAN = 'GERMAN',
  ITALIAN = 'ITALIAN',
  SPANISH = 'SPANISH'
}

export type Subscription = {
  __typename?: 'Subscription'
  quizGame: QuizGame
  quizGameClient: QuizGame
}

export type SubscriptionQuizGameArgs = {
  quizId: Scalars['ID']
}

export type SubscriptionQuizGameClientArgs = {
  qrCode: Scalars['String']
}

export type SupportedVersion = {
  __typename?: 'SupportedVersion'
  lastSupportedVersion?: Maybe<Version>
  recommendedVersion?: Maybe<Version>
}

export type Team = {
  __typename?: 'Team'
  imageURL: Scalars['String']
  name: Scalars['String']
}

export enum TimeUnits {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MILLISECOND = 'MILLISECOND',
  MINUTE = 'MINUTE',
  MONTH = 'MONTH',
  /** Determining time period from the beginning of the recording history. */
  OVERALL = 'OVERALL',
  SECOND = 'SECOND',
  WEEK = 'WEEK',
  YEAR = 'YEAR'
}

export enum TimeView {
  MONTH = 'MONTH',
  WEEK = 'WEEK'
}

export type TutorialProgress = {
  __typename?: 'TutorialProgress'
  isSkipped: Scalars['Boolean']
  tutorialStepCompleted: Scalars['Int']
}

export type UserAnswer = {
  __typename?: 'UserAnswer'
  assignmentText: Scalars['String']
  correctAnswer: Scalars['String']
  result: ActivityResult
  userAnswer?: Maybe<Scalars['String']>
}

export type UserBulkRegistration = {
  email: Scalars['String']
  name: Scalars['String']
}

export type UserBulkRegistrationEnvelopeResponse = {
  __typename?: 'UserBulkRegistrationEnvelopeResponse'
  initialCredentialsBase64?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
  users: Array<UserBulkRegistrationResponse>
}

export type UserBulkRegistrationError = {
  __typename?: 'UserBulkRegistrationError'
  code: Scalars['String']
  description: Scalars['String']
  field: Scalars['String']
}

export type UserBulkRegistrationResponse = {
  __typename?: 'UserBulkRegistrationResponse'
  email: Scalars['String']
  errors: Array<UserBulkRegistrationError>
  firstName: Scalars['String']
  isAlreadyRegistered: Scalars['Boolean']
  isValid: Scalars['Boolean']
  lastName: Scalars['String']
  originalName: Scalars['String']
}

export type UserLearningFeatureInput = {
  level?: InputMaybe<CEFRLevel>
  sourceLanguage: Language
  sourceLanguageText: Scalars['String']
  targetLanguage: Language
  targetLanguageText: Scalars['String']
  type?: InputMaybe<LearningFeatureType>
}

export type UserProfile = {
  __typename?: 'UserProfile'
  birthday?: Maybe<Scalars['Date']>
  email?: Maybe<Scalars['String']>
  firstName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
  pictureUrl?: Maybe<Scalars['String']>
  username: Scalars['String']
}

export type UserProfileInput = {
  birthday?: InputMaybe<Scalars['Date']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  pictureId?: InputMaybe<Scalars['ID']>
}

export enum UserRole {
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER'
}

export enum UserStatisticsSkills {
  LISTENING = 'LISTENING',
  READING = 'READING',
  SPEAKING = 'SPEAKING',
  WRITING = 'WRITING'
}

export type Version = {
  __typename?: 'Version'
  lastSupportDate?: Maybe<Scalars['Date']>
  version: Scalars['String']
}

export type WorksheetAllowedType = {
  __typename?: 'WorksheetAllowedType'
  allowedLexicalTypes: Array<GQLWorksheetLexicalType>
  worksheetType: GQLWorksheetType
}

export type WorksheetPdf = {
  __typename?: 'WorksheetPdf'
  contentBase64: Scalars['String']
  fileName: Scalars['String']
  worksheetLexicalType: GQLWorksheetLexicalType
  worksheetType: GQLWorksheetType
}

export type GetClassroomPerformanceReportQueryVariables = Exact<{
  classroomId: Scalars['ID']
}>

export type GetClassroomPerformanceReportQuery = {
  __typename?: 'Query'
  classroomPerformanceReport: Array<{
    __typename?: 'StudentPerformanceReportEnvelope'
    weekly: {
      __typename?: 'StudentPerformanceReport'
      student: {
        __typename?: 'Student'
        userProfile: {
          __typename?: 'UserProfile'
          id: string
          firstName: string
          lastName: string
          pictureUrl?: string | null
        }
      }
      tests?: {
        __typename?: 'LearningExecutionReport'
        learningActivityType: LearningActivityType
        progress: {
          __typename?: 'LearningActivityReportProgress'
          progressChange: ProgressChange
          progress: number
          total: number
          interventionNeeded: boolean
        }
      } | null
      homeworks?: {
        __typename?: 'LearningExecutionReport'
        learningActivityType: LearningActivityType
        progress: {
          __typename?: 'LearningActivityReportProgress'
          progressChange: ProgressChange
          progress: number
          total: number
          interventionNeeded: boolean
        }
      } | null
    }
    monthly: {
      __typename?: 'StudentPerformanceReport'
      student: {
        __typename?: 'Student'
        userProfile: {
          __typename?: 'UserProfile'
          id: string
          firstName: string
          lastName: string
          pictureUrl?: string | null
        }
      }
      tests?: {
        __typename?: 'LearningExecutionReport'
        learningActivityType: LearningActivityType
        progress: {
          __typename?: 'LearningActivityReportProgress'
          progressChange: ProgressChange
          progress: number
          total: number
          interventionNeeded: boolean
        }
      } | null
      homeworks?: {
        __typename?: 'LearningExecutionReport'
        learningActivityType: LearningActivityType
        progress: {
          __typename?: 'LearningActivityReportProgress'
          progressChange: ProgressChange
          progress: number
          total: number
          interventionNeeded: boolean
        }
      } | null
    }
  }>
}

export type GetStudentPerformanceDetailReportQueryVariables = Exact<{
  classroomId: Scalars['ID']
  studentId: Scalars['ID']
  type?: InputMaybe<LearningActivityType>
  page?: InputMaybe<PageableInput>
}>

export type GetStudentPerformanceDetailReportQuery = {
  __typename?: 'Query'
  studentPerformanceDetailReport: {
    __typename?: 'StudentPerformanceReportEnvelope'
    weekly: {
      __typename?: 'StudentPerformanceReport'
      summary: string
      studentSkillProgress: {
        __typename?: 'StudentSkillProgress'
        speakingProgress: number
        listeningProgress: number
        readingProgress: number
        writingProgress: number
        overallProgress: number
      }
      student: {
        __typename?: 'Student'
        userProfile: {
          __typename?: 'UserProfile'
          id: string
          firstName: string
          lastName: string
          pictureUrl?: string | null
        }
      }
      tests?: {
        __typename?: 'LearningExecutionReport'
        learningActivityType: LearningActivityType
        executions: Array<{
          __typename?: 'CompletedLearningExecution'
          summary?: string | null
          timestamp: string
          studyTimeSeconds: number
          classRank: number
          studentCount: number
          totalSkippedCount: number
          learningExecution: {
            __typename?: 'LearningExecution'
            id: string
            name: string
            successMeasure: number
            progress: number
            state: LearningExecutionState
          }
          answers: Array<{
            __typename?: 'FlashCardAnswer'
            flashCardId: string
            flashCardAssignmentType?: FlashCardAssignmentType | null
            flashCardResponseType?: FlashCardResponseType | null
            flashCardFullScreenType?: FullScreenFlashCardType | null
            points: number
            skipCount: number
            tryCount: number
            userAnswers: Array<{
              __typename?: 'UserAnswer'
              assignmentText: string
              userAnswer?: string | null
              correctAnswer: string
              result: ActivityResult
            }>
          }>
        }>
        progress: {
          __typename?: 'LearningActivityReportProgress'
          progressChange: ProgressChange
          progress: number
          total: number
        }
      } | null
      homeworks?: {
        __typename?: 'LearningExecutionReport'
        learningActivityType: LearningActivityType
        executions: Array<{
          __typename?: 'CompletedLearningExecution'
          summary?: string | null
          timestamp: string
          studyTimeSeconds: number
          classRank: number
          studentCount: number
          totalSkippedCount: number
          learningExecution: {
            __typename?: 'LearningExecution'
            id: string
            name: string
            successMeasure: number
            progress: number
            state: LearningExecutionState
          }
          answers: Array<{
            __typename?: 'FlashCardAnswer'
            flashCardId: string
            flashCardAssignmentType?: FlashCardAssignmentType | null
            flashCardResponseType?: FlashCardResponseType | null
            flashCardFullScreenType?: FullScreenFlashCardType | null
            points: number
            skipCount: number
            tryCount: number
            userAnswers: Array<{
              __typename?: 'UserAnswer'
              assignmentText: string
              userAnswer?: string | null
              correctAnswer: string
              result: ActivityResult
            }>
          }>
        }>
        progress: {
          __typename?: 'LearningActivityReportProgress'
          progressChange: ProgressChange
          progress: number
          total: number
        }
      } | null
      selfStudies?: {
        __typename?: 'LearningExecutionReport'
        learningActivityType: LearningActivityType
        executions: Array<{
          __typename?: 'CompletedLearningExecution'
          summary?: string | null
          timestamp: string
          studyTimeSeconds: number
          classRank: number
          studentCount: number
          totalSkippedCount: number
          learningExecution: {
            __typename?: 'LearningExecution'
            id: string
            name: string
            successMeasure: number
            progress: number
            state: LearningExecutionState
          }
          answers: Array<{
            __typename?: 'FlashCardAnswer'
            flashCardId: string
            flashCardAssignmentType?: FlashCardAssignmentType | null
            flashCardResponseType?: FlashCardResponseType | null
            flashCardFullScreenType?: FullScreenFlashCardType | null
            points: number
            skipCount: number
            tryCount: number
            userAnswers: Array<{
              __typename?: 'UserAnswer'
              assignmentText: string
              userAnswer?: string | null
              correctAnswer: string
              result: ActivityResult
            }>
          }>
        }>
        progress: {
          __typename?: 'LearningActivityReportProgress'
          progressChange: ProgressChange
          progress: number
          total: number
        }
      } | null
      quizzes?: {
        __typename?: 'LearningExecutionReport'
        learningActivityType: LearningActivityType
        executions: Array<{
          __typename?: 'CompletedLearningExecution'
          summary?: string | null
          timestamp: string
          studyTimeSeconds: number
          classRank: number
          studentCount: number
          totalSkippedCount: number
          learningExecution: {
            __typename?: 'LearningExecution'
            id: string
            name: string
            successMeasure: number
            progress: number
            state: LearningExecutionState
          }
          answers: Array<{
            __typename?: 'FlashCardAnswer'
            flashCardId: string
            flashCardAssignmentType?: FlashCardAssignmentType | null
            flashCardResponseType?: FlashCardResponseType | null
            flashCardFullScreenType?: FullScreenFlashCardType | null
            points: number
            skipCount: number
            tryCount: number
            userAnswers: Array<{
              __typename?: 'UserAnswer'
              assignmentText: string
              userAnswer?: string | null
              correctAnswer: string
              result: ActivityResult
            }>
          }>
        }>
        progress: {
          __typename?: 'LearningActivityReportProgress'
          progressChange: ProgressChange
          progress: number
          total: number
        }
      } | null
    }
    monthly: {
      __typename?: 'StudentPerformanceReport'
      summary: string
      studentSkillProgress: {
        __typename?: 'StudentSkillProgress'
        speakingProgress: number
        listeningProgress: number
        readingProgress: number
        writingProgress: number
        overallProgress: number
      }
      tests?: {
        __typename?: 'LearningExecutionReport'
        learningActivityType: LearningActivityType
        executions: Array<{
          __typename?: 'CompletedLearningExecution'
          summary?: string | null
          timestamp: string
          studyTimeSeconds: number
          classRank: number
          studentCount: number
          totalSkippedCount: number
          learningExecution: {
            __typename?: 'LearningExecution'
            id: string
            name: string
            successMeasure: number
            progress: number
            state: LearningExecutionState
          }
          answers: Array<{
            __typename?: 'FlashCardAnswer'
            flashCardId: string
            flashCardAssignmentType?: FlashCardAssignmentType | null
            flashCardResponseType?: FlashCardResponseType | null
            flashCardFullScreenType?: FullScreenFlashCardType | null
            points: number
            skipCount: number
            tryCount: number
            userAnswers: Array<{
              __typename?: 'UserAnswer'
              assignmentText: string
              userAnswer?: string | null
              correctAnswer: string
              result: ActivityResult
            }>
          }>
        }>
        progress: {
          __typename?: 'LearningActivityReportProgress'
          progressChange: ProgressChange
          progress: number
          total: number
        }
      } | null
      homeworks?: {
        __typename?: 'LearningExecutionReport'
        learningActivityType: LearningActivityType
        executions: Array<{
          __typename?: 'CompletedLearningExecution'
          summary?: string | null
          timestamp: string
          studyTimeSeconds: number
          classRank: number
          studentCount: number
          totalSkippedCount: number
          learningExecution: {
            __typename?: 'LearningExecution'
            id: string
            name: string
            successMeasure: number
            progress: number
            state: LearningExecutionState
          }
          answers: Array<{
            __typename?: 'FlashCardAnswer'
            flashCardId: string
            flashCardAssignmentType?: FlashCardAssignmentType | null
            flashCardResponseType?: FlashCardResponseType | null
            flashCardFullScreenType?: FullScreenFlashCardType | null
            points: number
            skipCount: number
            tryCount: number
            userAnswers: Array<{
              __typename?: 'UserAnswer'
              assignmentText: string
              userAnswer?: string | null
              correctAnswer: string
              result: ActivityResult
            }>
          }>
        }>
        progress: {
          __typename?: 'LearningActivityReportProgress'
          progressChange: ProgressChange
          progress: number
          total: number
        }
      } | null
      selfStudies?: {
        __typename?: 'LearningExecutionReport'
        learningActivityType: LearningActivityType
        executions: Array<{
          __typename?: 'CompletedLearningExecution'
          summary?: string | null
          timestamp: string
          studyTimeSeconds: number
          classRank: number
          studentCount: number
          totalSkippedCount: number
          learningExecution: {
            __typename?: 'LearningExecution'
            id: string
            name: string
            successMeasure: number
            progress: number
            state: LearningExecutionState
          }
          answers: Array<{
            __typename?: 'FlashCardAnswer'
            flashCardId: string
            flashCardAssignmentType?: FlashCardAssignmentType | null
            flashCardResponseType?: FlashCardResponseType | null
            flashCardFullScreenType?: FullScreenFlashCardType | null
            points: number
            skipCount: number
            tryCount: number
            userAnswers: Array<{
              __typename?: 'UserAnswer'
              assignmentText: string
              userAnswer?: string | null
              correctAnswer: string
              result: ActivityResult
            }>
          }>
        }>
        progress: {
          __typename?: 'LearningActivityReportProgress'
          progressChange: ProgressChange
          progress: number
          total: number
        }
      } | null
      quizzes?: {
        __typename?: 'LearningExecutionReport'
        learningActivityType: LearningActivityType
        executions: Array<{
          __typename?: 'CompletedLearningExecution'
          summary?: string | null
          timestamp: string
          studyTimeSeconds: number
          classRank: number
          studentCount: number
          totalSkippedCount: number
          learningExecution: {
            __typename?: 'LearningExecution'
            id: string
            name: string
            successMeasure: number
            progress: number
            state: LearningExecutionState
          }
          answers: Array<{
            __typename?: 'FlashCardAnswer'
            flashCardId: string
            flashCardAssignmentType?: FlashCardAssignmentType | null
            flashCardResponseType?: FlashCardResponseType | null
            flashCardFullScreenType?: FullScreenFlashCardType | null
            points: number
            skipCount: number
            tryCount: number
            userAnswers: Array<{
              __typename?: 'UserAnswer'
              assignmentText: string
              userAnswer?: string | null
              correctAnswer: string
              result: ActivityResult
            }>
          }>
        }>
        progress: {
          __typename?: 'LearningActivityReportProgress'
          progressChange: ProgressChange
          progress: number
          total: number
        }
      } | null
    }
  }
}

export type ExecutionReportFragmentFragment = {
  __typename?: 'LearningExecutionReport'
  learningActivityType: LearningActivityType
  executions: Array<{
    __typename?: 'CompletedLearningExecution'
    summary?: string | null
    timestamp: string
    studyTimeSeconds: number
    classRank: number
    studentCount: number
    totalSkippedCount: number
    learningExecution: {
      __typename?: 'LearningExecution'
      id: string
      name: string
      successMeasure: number
      progress: number
      state: LearningExecutionState
    }
    answers: Array<{
      __typename?: 'FlashCardAnswer'
      flashCardId: string
      flashCardAssignmentType?: FlashCardAssignmentType | null
      flashCardResponseType?: FlashCardResponseType | null
      flashCardFullScreenType?: FullScreenFlashCardType | null
      points: number
      skipCount: number
      tryCount: number
      userAnswers: Array<{
        __typename?: 'UserAnswer'
        assignmentText: string
        userAnswer?: string | null
        correctAnswer: string
        result: ActivityResult
      }>
    }>
  }>
  progress: {
    __typename?: 'LearningActivityReportProgress'
    progressChange: ProgressChange
    progress: number
    total: number
  }
}

export type BugReportMutationVariables = Exact<{
  message: BugReportMessageInput
}>

export type BugReportMutation = { __typename?: 'Mutation'; bugReport: string }

export type CreateClassroomMutationVariables = Exact<{
  classroomInput: ClassroomInput
}>

export type CreateClassroomMutation = {
  __typename?: 'Mutation'
  createClassroom: {
    __typename?: 'Classroom'
    id: string
    name: string
    pictureId?: string | null
    subject: Subject
    studentsInClassroom: Array<{
      __typename?: 'Student'
      userProfile: {
        __typename?: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        pictureUrl?: string | null
      }
    }>
  }
}

export type DeleteClassroomMutationVariables = Exact<{
  classroomId: Scalars['ID']
}>

export type DeleteClassroomMutation = { __typename?: 'Mutation'; deleteClassroom: boolean }

export type EditClassroomMutationVariables = Exact<{
  classroomId: Scalars['ID']
  classroomInput: ClassroomInput
}>

export type EditClassroomMutation = {
  __typename?: 'Mutation'
  editClassroom: {
    __typename?: 'Classroom'
    id: string
    name: string
    subject: Subject
    pictureId?: string | null
    book?: { __typename?: 'Learning'; id: string } | null
  }
}

export type GetClassroomDetailQueryVariables = Exact<{
  classroomId: Scalars['ID']
}>

export type GetClassroomDetailQuery = {
  __typename?: 'Query'
  classroom: {
    __typename: 'Classroom'
    id: string
    name: string
    subject: Subject
    pictureId?: string | null
    book?: { __typename?: 'Learning'; id: string; sourceLanguage: Language; subject: Subject } | null
    studentsInClassroom: Array<{
      __typename?: 'Student'
      studentLastLogin?: string | null
      userProfile: {
        __typename?: 'UserProfile'
        id: string
        pictureUrl?: string | null
        firstName: string
        lastName: string
        email?: string | null
      }
      studentDetail: {
        __typename?: 'StudentDetail'
        overallSuccessMeasure: number
        writingProgress: number
        speakingProgress: number
        listeningProgress: number
        readingProgress: number
      }
    }>
  }
}

export type GetClassroomHomeworksQueryVariables = Exact<{
  classroomId: Scalars['ID']
}>

export type GetClassroomHomeworksQuery = {
  __typename?: 'Query'
  classroomHomeworks: Array<{
    __typename?: 'LearningActivity'
    id: string
    name: string
    progress?: {
      __typename?: 'LearningActivityProgress'
      deadline?: string | null
      progress?: number | null
      total?: number | null
      type?: LearningActivityProgressType | null
    } | null
  }>
}

export type GetClassroomLoginCodeQueryVariables = Exact<{
  classroomId: Scalars['ID']
}>

export type GetClassroomLoginCodeQuery = {
  __typename?: 'Query'
  classroomLoginCode: { __typename?: 'ClassroomLoginCode'; classroomLoginCode: string; expirationTime: string }
}

export type GetCompletedLearningAssignmentQueryVariables = Exact<{
  learningExecutionId: Scalars['ID']
}>

export type GetCompletedLearningAssignmentQuery = {
  __typename?: 'Query'
  completedLearningExecution: {
    __typename?: 'CompletedLearningExecution'
    totalSkippedCount: number
    learningExecution: {
      __typename?: 'LearningExecution'
      id: string
      name: string
      progress: number
      successMeasure: number
      state: LearningExecutionState
      student: {
        __typename?: 'Student'
        userProfile: {
          __typename?: 'UserProfile'
          id: string
          pictureUrl?: string | null
          firstName: string
          lastName: string
        }
      }
      learningAssignment: {
        __typename?: 'LearningAssignment'
        type: LearningAssignmentType
        state: LearningAssignmentState
      }
    }
    answers: Array<{
      __typename?: 'FlashCardAnswer'
      flashCardAssignmentType?: FlashCardAssignmentType | null
      flashCardResponseType?: FlashCardResponseType | null
      flashCardFullScreenType?: FullScreenFlashCardType | null
      skipCount: number
      tryCount: number
      points: number
      userAnswers: Array<{
        __typename?: 'UserAnswer'
        assignmentText: string
        userAnswer?: string | null
        correctAnswer: string
        result: ActivityResult
      }>
    }>
  }
}

export type RemoveStudentFromClassMutationVariables = Exact<{
  studentId: Scalars['ID']
  classroomId: Scalars['ID']
}>

export type RemoveStudentFromClassMutation = { __typename?: 'Mutation'; removeStudentFromClass: boolean }

export type SearchLearningActivitiesQueryVariables = Exact<{
  parameters: LearningActivitiesSearchInput
  withExecutions: Scalars['Boolean']
  page: PageableInput
}>

export type SearchLearningActivitiesQuery = {
  __typename?: 'Query'
  searchLearningActivities: Array<{
    __typename?: 'LearningActivity'
    id: string
    name: string
    level2Name?: string | null
    state: LearningActivityState
    learningActivityType: LearningActivityType
    progress?: { __typename?: 'LearningActivityProgress'; deadline?: string | null; progress?: number | null } | null
    executions?: Array<{
      __typename?: 'LearningExecution'
      id: string
      state: LearningExecutionState
      name: string
      successMeasure: number
      progress: number
      student: {
        __typename?: 'Student'
        userProfile: {
          __typename?: 'UserProfile'
          id: string
          pictureUrl?: string | null
          firstName: string
          lastName: string
        }
      }
    }> | null
  }>
}

export type UserBulkRegistrationMutationVariables = Exact<{
  classroomId: Scalars['ID']
  users: Array<UserBulkRegistration> | UserBulkRegistration
  language: Language
}>

export type UserBulkRegistrationMutation = {
  __typename?: 'Mutation'
  userBulkRegistration: {
    __typename?: 'UserBulkRegistrationEnvelopeResponse'
    success: boolean
    initialCredentialsBase64?: string | null
    users: Array<{
      __typename?: 'UserBulkRegistrationResponse'
      originalName: string
      firstName: string
      lastName: string
      email: string
      isValid: boolean
      isAlreadyRegistered: boolean
      errors: Array<{ __typename?: 'UserBulkRegistrationError'; field: string; description: string; code: string }>
    }>
  }
}

export type UserBulkVerificationMutationVariables = Exact<{
  classroomId: Scalars['ID']
  users: Array<UserBulkRegistration> | UserBulkRegistration
}>

export type UserBulkVerificationMutation = {
  __typename?: 'Mutation'
  userBulkVerification: Array<{
    __typename?: 'UserBulkRegistrationResponse'
    originalName: string
    firstName: string
    lastName: string
    email: string
    isValid: boolean
    isAlreadyRegistered: boolean
    errors: Array<{ __typename?: 'UserBulkRegistrationError'; field: string; description: string; code: string }>
  }>
}

export type ConfirmReleaseNotesMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type ConfirmReleaseNotesMutation = { __typename?: 'Mutation'; confirmReleaseNotes: boolean }

export type GetClassroomsQueryVariables = Exact<{
  getWithStudents?: Scalars['Boolean']
}>

export type GetClassroomsQuery = {
  __typename?: 'Query'
  classrooms: Array<{
    __typename?: 'Classroom'
    id: string
    name: string
    pictureId?: string | null
    subject: Subject
    studentsInClassroom?: Array<{
      __typename?: 'Student'
      userProfile: {
        __typename?: 'UserProfile'
        id: string
        firstName: string
        lastName: string
        pictureUrl?: string | null
      }
    }>
  }>
}

export type GetReleaseNotesQueryVariables = Exact<{
  input: ReleaseNotesInput
  page: PageableInput
}>

export type GetReleaseNotesQuery = {
  __typename?: 'Query'
  releaseNotes: Array<{ __typename?: 'ReleaseNotes'; id: string; url: string }>
}

export type SearchLearningFeatureQueryVariables = Exact<{
  searchLearningFeatureInput: SearchLearningFeatureInput
}>

export type SearchLearningFeatureQuery = {
  __typename?: 'Query'
  searchLearningFeature: Array<{
    __typename?: 'LearningFeatureNew'
    id: string
    origin: LearningFeatureNewOrigin
    type: LearningFeatureType
    sourceLanguage: Language
    sourceLanguageText: string
    sourceSoundUrls: Array<string>
    sourceVideoUrls: Array<string>
    targetLanguage: Language
    targetLanguageText: string
    targetSoundUrls: Array<string>
    targetVideoUrls: Array<string>
    hasSynonyms: boolean
    hasAntonyms: boolean
    hasDefinitions: boolean
    images: Array<{
      __typename?: 'ImageType'
      thumbnail?: string | null
      small?: string | null
      medium?: string | null
      large?: string | null
      default: string
    }>
  }>
}

export type SearchDictionaryLearningFeatureQueryVariables = Exact<{
  searchLearningFeatureInput: SearchDictionaryLearningFeatureInput
  page: PageableInput
  filter?: InputMaybe<SearchDictionaryFilterInput>
}>

export type SearchDictionaryLearningFeatureQuery = {
  __typename?: 'Query'
  searchDictionaryLearningFeature: Array<{
    __typename: 'LearningFeatureNew'
    id: string
    origin: LearningFeatureNewOrigin
    type: LearningFeatureType
    sourceLanguage: Language
    targetLanguage: Language
    sourceLanguageText: string
    targetLanguageText: string
    level?: CEFRLevel | null
  }>
}

export type GetTutorialProgressQueryVariables = Exact<{ [key: string]: never }>

export type GetTutorialProgressQuery = {
  __typename?: 'Query'
  tutorialProgress: { __typename?: 'TutorialProgress'; tutorialStepCompleted: number; isSkipped: boolean }
}

export type UpdateTutorialProgressMutationVariables = Exact<{
  tutorialStepCompleted: Scalars['Int']
  isSkipped: Scalars['Boolean']
}>

export type UpdateTutorialProgressMutation = {
  __typename?: 'Mutation'
  updateTutorialProgress: { __typename?: 'TutorialProgress'; isSkipped: boolean; tutorialStepCompleted: number }
}

export type GetQuizGameQueryVariables = Exact<{
  quizId: Scalars['ID']
}>

export type GetQuizGameQuery = {
  __typename?: 'Query'
  quizGame: {
    __typename?: 'QuizGame'
    id: string
    name: string
    code: string
    gameState: QuizGameState
    numOfQuestions: number
    secondsPerQuestion: number
    showQuestionInClient: boolean
    countdownInSeconds: number
    currentQuestion?: {
      __typename?: 'QuizGameQuestion'
      id: string
      index: number
      assignmentType: QuizAssignmentType
      numOfAnswers: number
      hasNext: boolean
      assignment: {
        __typename: 'QuizGameQuestionItem'
        id: string
        soundURL?: string | null
        videoURL?: string | null
        language: Language
        text: string
        images?: { __typename?: 'ImageType'; medium?: string | null; large?: string | null; default: string } | null
      }
      responses: Array<{ __typename: 'QuizGameQuestionItem'; id: string; text: string }>
      questionResult: {
        __typename?: 'QuizGameQuestionResult'
        correctResponseId: string
        answerDistribution: Array<number>
      }
    } | null
    connectedPlayers: Array<{
      __typename?: 'QuizGamePlayer'
      id: string
      lastName: string
      firstName: string
      pictureUrl?: string | null
      newPosition: number
      oldPosition: number
      totalPoints: number
      newPoints: number
    } | null>
  }
}

export type NextQuizQuestionMutationVariables = Exact<{
  quizId: Scalars['ID']
}>

export type NextQuizQuestionMutation = { __typename?: 'Mutation'; nextQuizQuestion: boolean }

export type QuizGameSubscriptionVariables = Exact<{
  quizId: Scalars['ID']
}>

export type QuizGameSubscription = {
  __typename?: 'Subscription'
  quizGame: {
    __typename?: 'QuizGame'
    id: string
    name: string
    code: string
    gameState: QuizGameState
    numOfQuestions: number
    secondsPerQuestion: number
    showQuestionInClient: boolean
    countdownInSeconds: number
    currentQuestion?: {
      __typename?: 'QuizGameQuestion'
      id: string
      index: number
      assignmentType: QuizAssignmentType
      numOfAnswers: number
      hasNext: boolean
      assignment: {
        __typename?: 'QuizGameQuestionItem'
        id: string
        soundURL?: string | null
        videoURL?: string | null
        language: Language
        text: string
        images?: { __typename?: 'ImageType'; medium?: string | null; large?: string | null; default: string } | null
      }
      responses: Array<{ __typename?: 'QuizGameQuestionItem'; id: string; text: string }>
      questionResult: {
        __typename?: 'QuizGameQuestionResult'
        correctResponseId: string
        answerDistribution: Array<number>
      }
    } | null
    connectedPlayers: Array<{
      __typename?: 'QuizGamePlayer'
      id: string
      lastName: string
      firstName: string
      pictureUrl?: string | null
      newPosition: number
      oldPosition: number
      totalPoints: number
      newPoints: number
    } | null>
  }
}

export type StartQuizGameMutationVariables = Exact<{
  quizGameInput: QuizGameInput
}>

export type StartQuizGameMutation = {
  __typename?: 'Mutation'
  startQuizGame: { __typename?: 'QuizGame'; id: string; code: string }
}

export type AddUserLearningFeatureMutationVariables = Exact<{
  userLearningFeature: UserLearningFeatureInput
}>

export type AddUserLearningFeatureMutation = {
  __typename?: 'Mutation'
  addUserLearningFeature: {
    __typename?: 'LearningFeatureNew'
    id: string
    targetLanguageText: string
    origin: LearningFeatureNewOrigin
  }
}

export type CreateLearningActivityMutationVariables = Exact<{
  learningPlanLessonId: Scalars['ID']
  learningActivityInput: LearningActivityInput
}>

export type CreateLearningActivityMutation = {
  __typename?: 'Mutation'
  createLearningActivity: {
    __typename?: 'LearningActivity'
    id: string
    learningActivityExecutionId?: string | null
    previousActivityId?: string | null
    isInShelf: boolean
    name: string
    state: LearningActivityState
    tags: Array<string>
    learningActivityType: LearningActivityType
    estimatedTimeInSeconds: number
    learningFeatureCount: number
    learningFeatureFilter: Array<LearningFeatureType>
    durations: Array<{ __typename?: 'LearningActivityDuration'; durationInSeconds: number; isActive: boolean }>
    skills: Array<{ __typename?: 'LearningActivitySkill'; skill: Skill; isActive: boolean }>
    progress?: {
      __typename?: 'LearningActivityProgress'
      deadline?: string | null
      progress?: number | null
      total?: number | null
      type?: LearningActivityProgressType | null
    } | null
  }
}

export type CreateLearningPlanLessonMutationVariables = Exact<{
  classroomId: Scalars['ID']
  learningPlanLessonInput: LearningPlanLessonInput
}>

export type CreateLearningPlanLessonMutation = {
  __typename?: 'Mutation'
  createLearningPlanLesson: {
    __typename?: 'LearningPlanLesson'
    id: string
    isCurrent: boolean
    order1: number
    previousLessonId?: string | null
    level1Name?: string | null
    level2Name?: string | null
    isUserOwned?: boolean | null
    description?: string | null
    content?: Array<{
      __typename?: 'LearningFeatureNew'
      id: string
      targetLanguageText: string
      sourceLanguageText: string
      origin: LearningFeatureNewOrigin
    }> | null
    userContent?: Array<{
      __typename?: 'LearningFeatureNew'
      id: string
      targetLanguageText: string
      sourceLanguageText: string
      origin: LearningFeatureNewOrigin
    }> | null
    books?: Array<{
      __typename?: 'Book'
      id: string
      label: string
      sourceUrl?: string | null
      currentPage: number
      image: { __typename?: 'ImageType'; default: string; small?: string | null }
    }> | null
    learningActivities?: Array<{
      __typename?: 'LearningActivity'
      id: string
      learningActivityExecutionId?: string | null
      previousActivityId?: string | null
      isInShelf: boolean
      name: string
      state: LearningActivityState
      tags: Array<string>
      learningActivityType: LearningActivityType
      estimatedTimeInSeconds: number
      learningFeatureCount: number
      learningFeatureFilter: Array<LearningFeatureType>
      durations: Array<{ __typename?: 'LearningActivityDuration'; durationInSeconds: number; isActive: boolean }>
      skills: Array<{ __typename?: 'LearningActivitySkill'; skill: Skill; isActive: boolean }>
      progress?: {
        __typename?: 'LearningActivityProgress'
        deadline?: string | null
        progress?: number | null
        total?: number | null
        type?: LearningActivityProgressType | null
      } | null
    }> | null
  }
}

export type DeleteLearningActivityMutationVariables = Exact<{
  learningActivityId: Scalars['ID']
  learningActivitiesOrder: Array<Scalars['ID']> | Scalars['ID']
}>

export type DeleteLearningActivityMutation = { __typename?: 'Mutation'; deleteLearningActivity: boolean }

export type DeleteLearningPlanLessonMutationVariables = Exact<{
  learningPlanLessonId: Scalars['ID']
}>

export type DeleteLearningPlanLessonMutation = { __typename?: 'Mutation'; deleteLearningPlanLesson: boolean }

export type DeleteUserLearningFeatureMutationVariables = Exact<{
  learningFeatureId: Scalars['ID']
}>

export type DeleteUserLearningFeatureMutation = { __typename?: 'Mutation'; deleteUserLearningFeature: boolean }

export type GetBooksQueryVariables = Exact<{
  subject: Subject
}>

export type GetBooksQuery = {
  __typename?: 'Query'
  books: Array<{
    __typename?: 'Learning'
    id: string
    name: string
    type: LearningType
    sourceLanguage: Language
    image?: { __typename?: 'ImageType'; default: string; small?: string | null; medium?: string | null } | null
  }>
}

export type GetLearningActivityQueryVariables = Exact<{
  learningActivityId: Scalars['ID']
}>

export type GetLearningActivityQuery = {
  __typename?: 'Query'
  learningActivity: {
    __typename?: 'LearningActivity'
    id: string
    learningActivityExecutionId?: string | null
    previousActivityId?: string | null
    isInShelf: boolean
    name: string
    state: LearningActivityState
    tags: Array<string>
    learningActivityType: LearningActivityType
    estimatedTimeInSeconds: number
    learningFeatureCount: number
    learningFeatureFilter: Array<LearningFeatureType>
    durations: Array<{ __typename?: 'LearningActivityDuration'; durationInSeconds: number; isActive: boolean }>
    skills: Array<{ __typename?: 'LearningActivitySkill'; skill: Skill; isActive: boolean }>
    progress?: {
      __typename?: 'LearningActivityProgress'
      deadline?: string | null
      progress?: number | null
      total?: number | null
      type?: LearningActivityProgressType | null
    } | null
    learningFeatures: Array<{
      __typename?: 'LearningFeatureNew'
      id: string
      type: LearningFeatureType
      sourceLanguage: Language
      sourceLanguageText: string
      targetLanguage: Language
      targetLanguageText: string
      sourceSoundUrls: Array<string>
      targetSoundUrls: Array<string>
      sourceVideoUrls: Array<string>
      targetVideoUrls: Array<string>
      hasSynonyms: boolean
      hasAntonyms: boolean
      hasDefinitions: boolean
      images: Array<{ __typename?: 'ImageType'; default: string; small?: string | null }>
    }>
    gameProperties?: {
      __typename?: 'GameProperties'
      questionCount?: number | null
      coverStyle?: string | null
      isSoundEnabled?: boolean | null
      secondsPerQuestion?: number | null
      showQuestionInClient?: boolean | null
      title?: string | null
      worksheetType?: string | null
      worksheetLexicalType?: string | null
    } | null
  }
}

export type GetLearningActivityResultsQueryVariables = Exact<{
  learningActivityId: Scalars['ID']
}>

export type GetLearningActivityResultsQuery = {
  __typename?: 'Query'
  learningActivityResults: Array<{
    __typename?: 'LearningActivityResult'
    learningActivityId: string
    timestamp: string
    participantResults: Array<{
      __typename?: 'LearningActivityParticipantResult'
      learningActivityId: string
      interactions?: number | null
      totalAnswers?: number | null
      correctAnswers?: number | null
      successMeasure?: number | null
      progress?: number | null
      points?: number | null
      interventionNeeded: boolean
      answers: Array<{
        __typename?: 'FlashCardAnswer'
        flashCardId: string
        flashCardAssignmentType?: FlashCardAssignmentType | null
        flashCardResponseType?: FlashCardResponseType | null
        flashCardFullScreenType?: FullScreenFlashCardType | null
        skipCount: number
        tryCount: number
        points: number
        userAnswers: Array<{
          __typename?: 'UserAnswer'
          assignmentText: string
          userAnswer?: string | null
          correctAnswer: string
          result: ActivityResult
        }>
      }>
      participant:
        | {
            __typename?: 'Student'
            userProfile: {
              __typename?: 'UserProfile'
              id: string
              pictureUrl?: string | null
              firstName: string
              lastName: string
            }
          }
        | { __typename?: 'Team'; name: string; imageURL: string }
    }>
    frequentMistakes: Array<{
      __typename?: 'FrequentMistakes'
      mistakeCount: number
      learningFeature: { __typename?: 'LearningFeatureNew'; id: string; targetLanguageText: string }
    }>
    skillResults: Array<{
      __typename?: 'LearningActivitySkillResult'
      skill: UserStatisticsSkills
      successMeasure: number
    }>
  }>
}

export type GetLearningPlanContentQueryVariables = Exact<{
  classroomId: Scalars['ID']
  learningPlanLessonId: Scalars['ID']
}>

export type GetLearningPlanContentQuery = {
  __typename?: 'Query'
  learningPlanLessons: Array<{
    __typename?: 'LearningPlanLesson'
    content?: Array<{
      __typename?: 'LearningFeatureNew'
      id: string
      type: LearningFeatureType
      origin: LearningFeatureNewOrigin
      sourceLanguage: Language
      sourceLanguageText: string
      targetLanguage: Language
      targetLanguageText: string
      targetSoundUrls: Array<string>
      sourceSoundUrls: Array<string>
      sourceVideoUrls: Array<string>
      targetVideoUrls: Array<string>
      hasSynonyms: boolean
      hasAntonyms: boolean
      hasDefinitions: boolean
      images: Array<{ __typename?: 'ImageType'; default: string; small?: string | null }>
    }> | null
    userContent?: Array<{
      __typename?: 'LearningFeatureNew'
      id: string
      type: LearningFeatureType
      origin: LearningFeatureNewOrigin
      sourceLanguage: Language
      sourceLanguageText: string
      targetLanguage: Language
      targetLanguageText: string
      targetSoundUrls: Array<string>
      sourceSoundUrls: Array<string>
      sourceVideoUrls: Array<string>
      targetVideoUrls: Array<string>
      hasSynonyms: boolean
      hasAntonyms: boolean
      hasDefinitions: boolean
      images: Array<{ __typename?: 'ImageType'; default: string; small?: string | null }>
    }> | null
  }>
}

export type GetLearningPlanLessonsQueryVariables = Exact<{
  classroomId: Scalars['ID']
  withDetail: Scalars['Boolean']
  learningPlanLessonIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
  withLearningFeatures: Scalars['Boolean']
}>

export type GetLearningPlanLessonsQuery = {
  __typename?: 'Query'
  learningPlanLessons: Array<{
    __typename?: 'LearningPlanLesson'
    id: string
    isCurrent: boolean
    order1: number
    level1Name?: string | null
    level2Name?: string | null
    isUserOwned?: boolean | null
    previousLessonId?: string | null
    description?: string | null
    content?: Array<{
      __typename?: 'LearningFeatureNew'
      id: string
      targetLanguageText: string
      sourceLanguageText: string
      origin: LearningFeatureNewOrigin
    }> | null
    userContent?: Array<{
      __typename?: 'LearningFeatureNew'
      id: string
      targetLanguageText: string
      sourceLanguageText: string
      origin: LearningFeatureNewOrigin
    }> | null
    books?: Array<{
      __typename?: 'Book'
      id: string
      label: string
      sourceUrl?: string | null
      currentPage: number
      image: { __typename?: 'ImageType'; default: string; small?: string | null }
    }> | null
    learningActivities?: Array<{
      __typename?: 'LearningActivity'
      id: string
      learningActivityExecutionId?: string | null
      previousActivityId?: string | null
      isInShelf: boolean
      name: string
      state: LearningActivityState
      tags: Array<string>
      learningActivityType: LearningActivityType
      estimatedTimeInSeconds: number
      learningFeatureCount: number
      progress?: {
        __typename?: 'LearningActivityProgress'
        deadline?: string | null
        progress?: number | null
        total?: number | null
        type?: LearningActivityProgressType | null
      } | null
      gameProperties?: {
        __typename?: 'GameProperties'
        questionCount?: number | null
        coverStyle?: string | null
        isSoundEnabled?: boolean | null
        secondsPerQuestion?: number | null
        showQuestionInClient?: boolean | null
        title?: string | null
        worksheetType?: string | null
        worksheetLexicalType?: string | null
      } | null
      learningFeatures?: Array<{
        __typename?: 'LearningFeatureNew'
        id: string
        type: LearningFeatureType
        sourceLanguage: Language
        sourceLanguageText: string
        targetLanguage: Language
        targetLanguageText: string
        images: Array<{ __typename?: 'ImageType'; default: string; small?: string | null }>
      }>
    }> | null
  }>
}

export type LearningPlanDetailsFragment = {
  __typename?: 'LearningPlanLesson'
  description?: string | null
  content?: Array<{
    __typename?: 'LearningFeatureNew'
    id: string
    targetLanguageText: string
    sourceLanguageText: string
    origin: LearningFeatureNewOrigin
  }> | null
  userContent?: Array<{
    __typename?: 'LearningFeatureNew'
    id: string
    targetLanguageText: string
    sourceLanguageText: string
    origin: LearningFeatureNewOrigin
  }> | null
  books?: Array<{
    __typename?: 'Book'
    id: string
    label: string
    sourceUrl?: string | null
    currentPage: number
    image: { __typename?: 'ImageType'; default: string; small?: string | null }
  }> | null
  learningActivities?: Array<{
    __typename?: 'LearningActivity'
    id: string
    learningActivityExecutionId?: string | null
    previousActivityId?: string | null
    isInShelf: boolean
    name: string
    state: LearningActivityState
    tags: Array<string>
    learningActivityType: LearningActivityType
    estimatedTimeInSeconds: number
    learningFeatureCount: number
    progress?: {
      __typename?: 'LearningActivityProgress'
      deadline?: string | null
      progress?: number | null
      total?: number | null
      type?: LearningActivityProgressType | null
    } | null
    gameProperties?: {
      __typename?: 'GameProperties'
      questionCount?: number | null
      coverStyle?: string | null
      isSoundEnabled?: boolean | null
      secondsPerQuestion?: number | null
      showQuestionInClient?: boolean | null
      title?: string | null
      worksheetType?: string | null
      worksheetLexicalType?: string | null
    } | null
    learningFeatures?: Array<{
      __typename?: 'LearningFeatureNew'
      id: string
      type: LearningFeatureType
      sourceLanguage: Language
      sourceLanguageText: string
      targetLanguage: Language
      targetLanguageText: string
      images: Array<{ __typename?: 'ImageType'; default: string; small?: string | null }>
    }>
  }> | null
}

export type GetSubjectsQueryVariables = Exact<{ [key: string]: never }>

export type GetSubjectsQuery = { __typename?: 'Query'; subjects: Array<Subject> }

export type GetWorksheetsAllowedTypesQueryVariables = Exact<{ [key: string]: never }>

export type GetWorksheetsAllowedTypesQuery = {
  __typename?: 'Query'
  worksheetsAllowedTypes: Array<{
    __typename?: 'WorksheetAllowedType'
    worksheetType: GQLWorksheetType
    allowedLexicalTypes: Array<GQLWorksheetLexicalType>
  }>
}

export type StartLearningActivityMutationVariables = Exact<{
  learningActivityId: Scalars['ID']
}>

export type StartLearningActivityMutation = {
  __typename?: 'Mutation'
  startLearningActivity: {
    __typename?: 'LearningActivity'
    id: string
    state: LearningActivityState
    estimatedTimeInSeconds: number
    learningActivityType: LearningActivityType
    name: string
    learningActivityExecutionId?: string | null
    previousActivityId?: string | null
    isInShelf: boolean
    learningFeatureCount: number
    learningFeatureFilter: Array<LearningFeatureType>
    progress?: {
      __typename?: 'LearningActivityProgress'
      deadline?: string | null
      progress?: number | null
      total?: number | null
      type?: LearningActivityProgressType | null
    } | null
    learningFeatures: Array<{
      __typename?: 'LearningFeatureNew'
      id: string
      type: LearningFeatureType
      sourceLanguage: Language
      sourceLanguageText: string
      sourceSoundUrls: Array<string>
      targetLanguage: Language
      targetLanguageText: string
      targetSoundUrls: Array<string>
      images: Array<{ __typename?: 'ImageType'; default: string; medium?: string | null }>
    }>
    gameProperties?: {
      __typename?: 'GameProperties'
      questionCount?: number | null
      coverStyle?: string | null
      isSoundEnabled?: boolean | null
      secondsPerQuestion?: number | null
      showQuestionInClient?: boolean | null
      title?: string | null
      worksheetType?: string | null
      worksheetLexicalType?: string | null
    } | null
  }
}

export type StopLearningActivityMutationVariables = Exact<{
  learningActivityId: Scalars['ID']
}>

export type StopLearningActivityMutation = {
  __typename?: 'Mutation'
  stopLearningActivity: {
    __typename?: 'LearningActivity'
    id: string
    state: LearningActivityState
    learningActivityExecutionId?: string | null
    previousActivityId?: string | null
    isInShelf: boolean
    progress?: {
      __typename?: 'LearningActivityProgress'
      deadline?: string | null
      progress?: number | null
      total?: number | null
      type?: LearningActivityProgressType | null
    } | null
  }
}

export type UpdateLearningActivityMutationVariables = Exact<{
  learningActivityId: Scalars['ID']
  learningActivityInput: LearningActivityInput
}>

export type UpdateLearningActivityMutation = {
  __typename?: 'Mutation'
  updateLearningActivity: {
    __typename?: 'LearningActivity'
    id: string
    learningActivityExecutionId?: string | null
    previousActivityId?: string | null
    isInShelf: boolean
    name: string
    state: LearningActivityState
    tags: Array<string>
    learningActivityType: LearningActivityType
    estimatedTimeInSeconds: number
    learningFeatureCount: number
    learningFeatureFilter: Array<LearningFeatureType>
    durations: Array<{ __typename?: 'LearningActivityDuration'; durationInSeconds: number; isActive: boolean }>
    skills: Array<{ __typename?: 'LearningActivitySkill'; skill: Skill; isActive: boolean }>
    progress?: {
      __typename?: 'LearningActivityProgress'
      deadline?: string | null
      progress?: number | null
      total?: number | null
      type?: LearningActivityProgressType | null
    } | null
    learningFeatures: Array<{
      __typename?: 'LearningFeatureNew'
      id: string
      type: LearningFeatureType
      sourceLanguage: Language
      sourceLanguageText: string
      targetLanguage: Language
      targetLanguageText: string
      sourceSoundUrls: Array<string>
      targetSoundUrls: Array<string>
      sourceVideoUrls: Array<string>
      targetVideoUrls: Array<string>
      images: Array<{ __typename?: 'ImageType'; default: string; small?: string | null }>
    }>
    gameProperties?: {
      __typename?: 'GameProperties'
      questionCount?: number | null
      coverStyle?: string | null
      isSoundEnabled?: boolean | null
      secondsPerQuestion?: number | null
      showQuestionInClient?: boolean | null
      title?: string | null
      worksheetType?: string | null
      worksheetLexicalType?: string | null
    } | null
  }
}

export type UpdateLearningPlanLessonMutationVariables = Exact<{
  learningPlanLessonId: Scalars['ID']
  learningPlanLessonInput: LearningPlanLessonInput
}>

export type UpdateLearningPlanLessonMutation = {
  __typename?: 'Mutation'
  updateLearningPlanLesson: {
    __typename?: 'LearningPlanLesson'
    id: string
    level1Name?: string | null
    level2Name?: string | null
    isUserOwned?: boolean | null
    content?: Array<{
      __typename?: 'LearningFeatureNew'
      id: string
      targetLanguageText: string
      sourceLanguageText: string
      origin: LearningFeatureNewOrigin
    }> | null
    userContent?: Array<{
      __typename?: 'LearningFeatureNew'
      id: string
      targetLanguageText: string
      sourceLanguageText: string
      origin: LearningFeatureNewOrigin
    }> | null
  }
}

export type UpdateUserLearningFeatureMutationVariables = Exact<{
  learningFeatureId: Scalars['ID']
  userLearningFeature: UserLearningFeatureInput
}>

export type UpdateUserLearningFeatureMutation = {
  __typename?: 'Mutation'
  updateUserLearningFeature: {
    __typename: 'LearningFeatureNew'
    id: string
    sourceLanguage: Language
    sourceLanguageText: string
    targetLanguage: Language
    targetLanguageText: string
    level?: CEFRLevel | null
    type: LearningFeatureType
    origin: LearningFeatureNewOrigin
  }
}

export type GetWorksheetsForActivityQueryVariables = Exact<{
  learningActivityId: Scalars['ID']
}>

export type GetWorksheetsForActivityQuery = {
  __typename?: 'Query'
  worksheetsForActivity: Array<{
    __typename?: 'WorksheetPdf'
    fileName: string
    worksheetType: GQLWorksheetType
    worksheetLexicalType: GQLWorksheetLexicalType
    contentBase64: string
  }>
}

export type AddUserRoleMutationVariables = Exact<{
  userRole: UserRole
}>

export type AddUserRoleMutation = { __typename?: 'Mutation'; addUserRole: boolean }

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never }>

export type GetUserProfileQuery = {
  __typename?: 'Query'
  userProfile: {
    __typename?: 'UserProfile'
    id: string
    pictureUrl?: string | null
    firstName: string
    lastName: string
    username: string
    birthday?: string | null
    email?: string | null
  }
}

export type UpdateUserProfileMutationVariables = Exact<{
  userProfile?: InputMaybe<UserProfileInput>
}>

export type UpdateUserProfileMutation = {
  __typename?: 'Mutation'
  updateUserProfile: {
    __typename?: 'UserProfile'
    id: string
    firstName: string
    lastName: string
    birthday?: string | null
    pictureUrl?: string | null
  }
}

export const ExecutionReportFragmentFragmentDoc = gql`
  fragment ExecutionReportFragment on LearningExecutionReport {
    learningActivityType
    executions {
      summary
      timestamp
      studyTimeSeconds
      classRank
      studentCount
      totalSkippedCount
      learningExecution {
        id
        name
        successMeasure
        progress
        state
      }
      answers {
        flashCardId
        flashCardAssignmentType
        flashCardResponseType
        flashCardFullScreenType
        points
        skipCount
        tryCount
        userAnswers {
          assignmentText
          userAnswer
          correctAnswer
          result
        }
      }
    }
    progress {
      progressChange
      progress
      total
    }
  }
`
export const LearningPlanDetailsFragmentDoc = gql`
  fragment LearningPlanDetails on LearningPlanLesson {
    description
    content {
      id
      targetLanguageText
      sourceLanguageText
      origin
    }
    userContent {
      id
      targetLanguageText
      sourceLanguageText
      origin
    }
    books {
      id
      label
      image {
        default
        small
      }
      sourceUrl
      currentPage
    }
    learningActivities {
      id
      learningActivityExecutionId
      previousActivityId
      isInShelf
      name
      state
      tags
      learningActivityType
      estimatedTimeInSeconds
      progress {
        deadline
        progress
        total
        type
      }
      learningFeatureCount
      gameProperties {
        questionCount
        coverStyle
        isSoundEnabled
        secondsPerQuestion
        showQuestionInClient
        title
        worksheetType
        worksheetLexicalType
      }
      learningFeatures @include(if: $withLearningFeatures) {
        id
        images {
          default
          small
        }
        type
        sourceLanguage
        sourceLanguageText
        targetLanguage
        targetLanguageText
      }
    }
  }
`
export const GetClassroomPerformanceReportDocument = gql`
  query getClassroomPerformanceReport($classroomId: ID!) {
    classroomPerformanceReport(classroomId: $classroomId) {
      weekly {
        student {
          userProfile {
            id
            firstName
            lastName
            pictureUrl
          }
        }
        tests {
          learningActivityType
          progress {
            progressChange
            progress
            total
            interventionNeeded
          }
        }
        homeworks {
          learningActivityType
          progress {
            progressChange
            progress
            total
            interventionNeeded
          }
        }
      }
      monthly {
        student {
          userProfile {
            id
            firstName
            lastName
            pictureUrl
          }
        }
        tests {
          learningActivityType
          progress {
            progressChange
            progress
            total
            interventionNeeded
          }
        }
        homeworks {
          learningActivityType
          progress {
            progressChange
            progress
            total
            interventionNeeded
          }
        }
      }
    }
  }
`

/**
 * __useGetClassroomPerformanceReportQuery__
 *
 * To run a query within a React component, call `useGetClassroomPerformanceReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassroomPerformanceReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassroomPerformanceReportQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useGetClassroomPerformanceReportQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetClassroomPerformanceReportQuery,
    GetClassroomPerformanceReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetClassroomPerformanceReportQuery, GetClassroomPerformanceReportQueryVariables>(
    GetClassroomPerformanceReportDocument,
    options
  )
}
export function useGetClassroomPerformanceReportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetClassroomPerformanceReportQuery,
    GetClassroomPerformanceReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetClassroomPerformanceReportQuery, GetClassroomPerformanceReportQueryVariables>(
    GetClassroomPerformanceReportDocument,
    options
  )
}
export type GetClassroomPerformanceReportQueryHookResult = ReturnType<typeof useGetClassroomPerformanceReportQuery>
export type GetClassroomPerformanceReportLazyQueryHookResult = ReturnType<
  typeof useGetClassroomPerformanceReportLazyQuery
>
export type GetClassroomPerformanceReportQueryResult = Apollo.QueryResult<
  GetClassroomPerformanceReportQuery,
  GetClassroomPerformanceReportQueryVariables
>
export const GetStudentPerformanceDetailReportDocument = gql`
  query getStudentPerformanceDetailReport(
    $classroomId: ID!
    $studentId: ID!
    $type: LearningActivityType
    $page: PageableInput
  ) {
    studentPerformanceDetailReport(classroomId: $classroomId, studentId: $studentId, type: $type, page: $page) {
      weekly {
        summary
        studentSkillProgress {
          speakingProgress
          listeningProgress
          readingProgress
          writingProgress
          overallProgress
        }
        student {
          userProfile {
            id
            firstName
            lastName
            pictureUrl
          }
        }
        tests {
          ...ExecutionReportFragment
        }
        homeworks {
          ...ExecutionReportFragment
        }
        selfStudies {
          ...ExecutionReportFragment
        }
        quizzes {
          ...ExecutionReportFragment
        }
      }
      monthly {
        summary
        studentSkillProgress {
          speakingProgress
          listeningProgress
          readingProgress
          writingProgress
          overallProgress
        }
        tests {
          ...ExecutionReportFragment
        }
        homeworks {
          ...ExecutionReportFragment
        }
        selfStudies {
          ...ExecutionReportFragment
        }
        quizzes {
          ...ExecutionReportFragment
        }
      }
    }
  }
  ${ExecutionReportFragmentFragmentDoc}
`

/**
 * __useGetStudentPerformanceDetailReportQuery__
 *
 * To run a query within a React component, call `useGetStudentPerformanceDetailReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentPerformanceDetailReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentPerformanceDetailReportQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      studentId: // value for 'studentId'
 *      type: // value for 'type'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetStudentPerformanceDetailReportQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetStudentPerformanceDetailReportQuery,
    GetStudentPerformanceDetailReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<
    GetStudentPerformanceDetailReportQuery,
    GetStudentPerformanceDetailReportQueryVariables
  >(GetStudentPerformanceDetailReportDocument, options)
}
export function useGetStudentPerformanceDetailReportLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetStudentPerformanceDetailReportQuery,
    GetStudentPerformanceDetailReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetStudentPerformanceDetailReportQuery,
    GetStudentPerformanceDetailReportQueryVariables
  >(GetStudentPerformanceDetailReportDocument, options)
}
export type GetStudentPerformanceDetailReportQueryHookResult = ReturnType<
  typeof useGetStudentPerformanceDetailReportQuery
>
export type GetStudentPerformanceDetailReportLazyQueryHookResult = ReturnType<
  typeof useGetStudentPerformanceDetailReportLazyQuery
>
export type GetStudentPerformanceDetailReportQueryResult = Apollo.QueryResult<
  GetStudentPerformanceDetailReportQuery,
  GetStudentPerformanceDetailReportQueryVariables
>
export const BugReportDocument = gql`
  mutation bugReport($message: BugReportMessageInput!) {
    bugReport(message: $message)
  }
`
export type BugReportMutationFn = Apollo.MutationFunction<BugReportMutation, BugReportMutationVariables>

/**
 * __useBugReportMutation__
 *
 * To run a mutation, you first call `useBugReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBugReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bugReportMutation, { data, loading, error }] = useBugReportMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useBugReportMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<BugReportMutation, BugReportMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<BugReportMutation, BugReportMutationVariables>(BugReportDocument, options)
}
export type BugReportMutationHookResult = ReturnType<typeof useBugReportMutation>
export type BugReportMutationResult = Apollo.MutationResult<BugReportMutation>
export type BugReportMutationOptions = Apollo.BaseMutationOptions<BugReportMutation, BugReportMutationVariables>
export const CreateClassroomDocument = gql`
  mutation createClassroom($classroomInput: ClassroomInput!) {
    createClassroom(classroomInput: $classroomInput) {
      id
      name
      pictureId
      subject
      studentsInClassroom {
        userProfile {
          id
          firstName
          lastName
          pictureUrl
        }
      }
    }
  }
`
export type CreateClassroomMutationFn = Apollo.MutationFunction<
  CreateClassroomMutation,
  CreateClassroomMutationVariables
>

/**
 * __useCreateClassroomMutation__
 *
 * To run a mutation, you first call `useCreateClassroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassroomMutation, { data, loading, error }] = useCreateClassroomMutation({
 *   variables: {
 *      classroomInput: // value for 'classroomInput'
 *   },
 * });
 */
export function useCreateClassroomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateClassroomMutation, CreateClassroomMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<CreateClassroomMutation, CreateClassroomMutationVariables>(
    CreateClassroomDocument,
    options
  )
}
export type CreateClassroomMutationHookResult = ReturnType<typeof useCreateClassroomMutation>
export type CreateClassroomMutationResult = Apollo.MutationResult<CreateClassroomMutation>
export type CreateClassroomMutationOptions = Apollo.BaseMutationOptions<
  CreateClassroomMutation,
  CreateClassroomMutationVariables
>
export const DeleteClassroomDocument = gql`
  mutation deleteClassroom($classroomId: ID!) {
    deleteClassroom(classroomId: $classroomId)
  }
`
export type DeleteClassroomMutationFn = Apollo.MutationFunction<
  DeleteClassroomMutation,
  DeleteClassroomMutationVariables
>

/**
 * __useDeleteClassroomMutation__
 *
 * To run a mutation, you first call `useDeleteClassroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassroomMutation, { data, loading, error }] = useDeleteClassroomMutation({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useDeleteClassroomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteClassroomMutation, DeleteClassroomMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<DeleteClassroomMutation, DeleteClassroomMutationVariables>(
    DeleteClassroomDocument,
    options
  )
}
export type DeleteClassroomMutationHookResult = ReturnType<typeof useDeleteClassroomMutation>
export type DeleteClassroomMutationResult = Apollo.MutationResult<DeleteClassroomMutation>
export type DeleteClassroomMutationOptions = Apollo.BaseMutationOptions<
  DeleteClassroomMutation,
  DeleteClassroomMutationVariables
>
export const EditClassroomDocument = gql`
  mutation editClassroom($classroomId: ID!, $classroomInput: ClassroomInput!) {
    editClassroom(classroomId: $classroomId, classroomInput: $classroomInput) {
      id
      name
      subject
      pictureId
      book {
        id
      }
    }
  }
`
export type EditClassroomMutationFn = Apollo.MutationFunction<EditClassroomMutation, EditClassroomMutationVariables>

/**
 * __useEditClassroomMutation__
 *
 * To run a mutation, you first call `useEditClassroomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditClassroomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editClassroomMutation, { data, loading, error }] = useEditClassroomMutation({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      classroomInput: // value for 'classroomInput'
 *   },
 * });
 */
export function useEditClassroomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<EditClassroomMutation, EditClassroomMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<EditClassroomMutation, EditClassroomMutationVariables>(
    EditClassroomDocument,
    options
  )
}
export type EditClassroomMutationHookResult = ReturnType<typeof useEditClassroomMutation>
export type EditClassroomMutationResult = Apollo.MutationResult<EditClassroomMutation>
export type EditClassroomMutationOptions = Apollo.BaseMutationOptions<
  EditClassroomMutation,
  EditClassroomMutationVariables
>
export const GetClassroomDetailDocument = gql`
  query getClassroomDetail($classroomId: ID!) {
    classroom(classroomId: $classroomId) {
      __typename
      id
      name
      subject
      pictureId
      book {
        id
        sourceLanguage
        subject
      }
      studentsInClassroom {
        userProfile {
          id
          pictureUrl
          firstName
          lastName
          email
        }
        studentLastLogin
        studentDetail {
          overallSuccessMeasure
          writingProgress
          speakingProgress
          listeningProgress
          readingProgress
        }
      }
    }
  }
`

/**
 * __useGetClassroomDetailQuery__
 *
 * To run a query within a React component, call `useGetClassroomDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassroomDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassroomDetailQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useGetClassroomDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetClassroomDetailQuery, GetClassroomDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetClassroomDetailQuery, GetClassroomDetailQueryVariables>(
    GetClassroomDetailDocument,
    options
  )
}
export function useGetClassroomDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClassroomDetailQuery, GetClassroomDetailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetClassroomDetailQuery, GetClassroomDetailQueryVariables>(
    GetClassroomDetailDocument,
    options
  )
}
export type GetClassroomDetailQueryHookResult = ReturnType<typeof useGetClassroomDetailQuery>
export type GetClassroomDetailLazyQueryHookResult = ReturnType<typeof useGetClassroomDetailLazyQuery>
export type GetClassroomDetailQueryResult = Apollo.QueryResult<
  GetClassroomDetailQuery,
  GetClassroomDetailQueryVariables
>
export const GetClassroomHomeworksDocument = gql`
  query getClassroomHomeworks($classroomId: ID!) {
    classroomHomeworks(classroomId: $classroomId) {
      id
      name
      progress {
        deadline
        progress
        total
        type
      }
    }
  }
`

/**
 * __useGetClassroomHomeworksQuery__
 *
 * To run a query within a React component, call `useGetClassroomHomeworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassroomHomeworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassroomHomeworksQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useGetClassroomHomeworksQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetClassroomHomeworksQuery, GetClassroomHomeworksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetClassroomHomeworksQuery, GetClassroomHomeworksQueryVariables>(
    GetClassroomHomeworksDocument,
    options
  )
}
export function useGetClassroomHomeworksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClassroomHomeworksQuery, GetClassroomHomeworksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetClassroomHomeworksQuery, GetClassroomHomeworksQueryVariables>(
    GetClassroomHomeworksDocument,
    options
  )
}
export type GetClassroomHomeworksQueryHookResult = ReturnType<typeof useGetClassroomHomeworksQuery>
export type GetClassroomHomeworksLazyQueryHookResult = ReturnType<typeof useGetClassroomHomeworksLazyQuery>
export type GetClassroomHomeworksQueryResult = Apollo.QueryResult<
  GetClassroomHomeworksQuery,
  GetClassroomHomeworksQueryVariables
>
export const GetClassroomLoginCodeDocument = gql`
  query getClassroomLoginCode($classroomId: ID!) {
    classroomLoginCode(classroomId: $classroomId) {
      classroomLoginCode
      expirationTime
    }
  }
`

/**
 * __useGetClassroomLoginCodeQuery__
 *
 * To run a query within a React component, call `useGetClassroomLoginCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassroomLoginCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassroomLoginCodeQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useGetClassroomLoginCodeQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetClassroomLoginCodeQuery, GetClassroomLoginCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetClassroomLoginCodeQuery, GetClassroomLoginCodeQueryVariables>(
    GetClassroomLoginCodeDocument,
    options
  )
}
export function useGetClassroomLoginCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClassroomLoginCodeQuery, GetClassroomLoginCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetClassroomLoginCodeQuery, GetClassroomLoginCodeQueryVariables>(
    GetClassroomLoginCodeDocument,
    options
  )
}
export type GetClassroomLoginCodeQueryHookResult = ReturnType<typeof useGetClassroomLoginCodeQuery>
export type GetClassroomLoginCodeLazyQueryHookResult = ReturnType<typeof useGetClassroomLoginCodeLazyQuery>
export type GetClassroomLoginCodeQueryResult = Apollo.QueryResult<
  GetClassroomLoginCodeQuery,
  GetClassroomLoginCodeQueryVariables
>
export const GetCompletedLearningAssignmentDocument = gql`
  query getCompletedLearningAssignment($learningExecutionId: ID!) {
    completedLearningExecution(learningExecutionId: $learningExecutionId) {
      totalSkippedCount
      learningExecution {
        id
        name
        progress
        successMeasure
        state
        student {
          userProfile {
            id
            pictureUrl
            firstName
            lastName
          }
        }
        learningAssignment {
          type
          state
        }
      }
      answers {
        flashCardAssignmentType
        flashCardResponseType
        flashCardFullScreenType
        skipCount
        tryCount
        points
        userAnswers {
          assignmentText
          userAnswer
          correctAnswer
          result
        }
      }
    }
  }
`

/**
 * __useGetCompletedLearningAssignmentQuery__
 *
 * To run a query within a React component, call `useGetCompletedLearningAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedLearningAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedLearningAssignmentQuery({
 *   variables: {
 *      learningExecutionId: // value for 'learningExecutionId'
 *   },
 * });
 */
export function useGetCompletedLearningAssignmentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetCompletedLearningAssignmentQuery,
    GetCompletedLearningAssignmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetCompletedLearningAssignmentQuery, GetCompletedLearningAssignmentQueryVariables>(
    GetCompletedLearningAssignmentDocument,
    options
  )
}
export function useGetCompletedLearningAssignmentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetCompletedLearningAssignmentQuery,
    GetCompletedLearningAssignmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    GetCompletedLearningAssignmentQuery,
    GetCompletedLearningAssignmentQueryVariables
  >(GetCompletedLearningAssignmentDocument, options)
}
export type GetCompletedLearningAssignmentQueryHookResult = ReturnType<typeof useGetCompletedLearningAssignmentQuery>
export type GetCompletedLearningAssignmentLazyQueryHookResult = ReturnType<
  typeof useGetCompletedLearningAssignmentLazyQuery
>
export type GetCompletedLearningAssignmentQueryResult = Apollo.QueryResult<
  GetCompletedLearningAssignmentQuery,
  GetCompletedLearningAssignmentQueryVariables
>
export const RemoveStudentFromClassDocument = gql`
  mutation removeStudentFromClass($studentId: ID!, $classroomId: ID!) {
    removeStudentFromClass(studentId: $studentId, classroomId: $classroomId)
  }
`
export type RemoveStudentFromClassMutationFn = Apollo.MutationFunction<
  RemoveStudentFromClassMutation,
  RemoveStudentFromClassMutationVariables
>

/**
 * __useRemoveStudentFromClassMutation__
 *
 * To run a mutation, you first call `useRemoveStudentFromClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStudentFromClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStudentFromClassMutation, { data, loading, error }] = useRemoveStudentFromClassMutation({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      classroomId: // value for 'classroomId'
 *   },
 * });
 */
export function useRemoveStudentFromClassMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveStudentFromClassMutation,
    RemoveStudentFromClassMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<RemoveStudentFromClassMutation, RemoveStudentFromClassMutationVariables>(
    RemoveStudentFromClassDocument,
    options
  )
}
export type RemoveStudentFromClassMutationHookResult = ReturnType<typeof useRemoveStudentFromClassMutation>
export type RemoveStudentFromClassMutationResult = Apollo.MutationResult<RemoveStudentFromClassMutation>
export type RemoveStudentFromClassMutationOptions = Apollo.BaseMutationOptions<
  RemoveStudentFromClassMutation,
  RemoveStudentFromClassMutationVariables
>
export const SearchLearningActivitiesDocument = gql`
  query searchLearningActivities(
    $parameters: LearningActivitiesSearchInput!
    $withExecutions: Boolean!
    $page: PageableInput!
  ) {
    searchLearningActivities(parameters: $parameters, page: $page) {
      id
      name
      level2Name
      state
      learningActivityType
      progress @include(if: $withExecutions) {
        deadline
        progress
      }
      executions @include(if: $withExecutions) {
        id
        state
        name
        successMeasure
        progress
        student {
          userProfile {
            id
            pictureUrl
            firstName
            lastName
          }
        }
      }
    }
  }
`

/**
 * __useSearchLearningActivitiesQuery__
 *
 * To run a query within a React component, call `useSearchLearningActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLearningActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLearningActivitiesQuery({
 *   variables: {
 *      parameters: // value for 'parameters'
 *      withExecutions: // value for 'withExecutions'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSearchLearningActivitiesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SearchLearningActivitiesQuery, SearchLearningActivitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<SearchLearningActivitiesQuery, SearchLearningActivitiesQueryVariables>(
    SearchLearningActivitiesDocument,
    options
  )
}
export function useSearchLearningActivitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SearchLearningActivitiesQuery,
    SearchLearningActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<SearchLearningActivitiesQuery, SearchLearningActivitiesQueryVariables>(
    SearchLearningActivitiesDocument,
    options
  )
}
export type SearchLearningActivitiesQueryHookResult = ReturnType<typeof useSearchLearningActivitiesQuery>
export type SearchLearningActivitiesLazyQueryHookResult = ReturnType<typeof useSearchLearningActivitiesLazyQuery>
export type SearchLearningActivitiesQueryResult = Apollo.QueryResult<
  SearchLearningActivitiesQuery,
  SearchLearningActivitiesQueryVariables
>
export const UserBulkRegistrationDocument = gql`
  mutation userBulkRegistration($classroomId: ID!, $users: [UserBulkRegistration!]!, $language: Language!) {
    userBulkRegistration(classroomId: $classroomId, users: $users, language: $language) {
      success
      users {
        originalName
        firstName
        lastName
        email
        isValid
        errors {
          field
          description
          code
        }
        isAlreadyRegistered
      }
      initialCredentialsBase64
    }
  }
`
export type UserBulkRegistrationMutationFn = Apollo.MutationFunction<
  UserBulkRegistrationMutation,
  UserBulkRegistrationMutationVariables
>

/**
 * __useUserBulkRegistrationMutation__
 *
 * To run a mutation, you first call `useUserBulkRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserBulkRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userBulkRegistrationMutation, { data, loading, error }] = useUserBulkRegistrationMutation({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      users: // value for 'users'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUserBulkRegistrationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserBulkRegistrationMutation,
    UserBulkRegistrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UserBulkRegistrationMutation, UserBulkRegistrationMutationVariables>(
    UserBulkRegistrationDocument,
    options
  )
}
export type UserBulkRegistrationMutationHookResult = ReturnType<typeof useUserBulkRegistrationMutation>
export type UserBulkRegistrationMutationResult = Apollo.MutationResult<UserBulkRegistrationMutation>
export type UserBulkRegistrationMutationOptions = Apollo.BaseMutationOptions<
  UserBulkRegistrationMutation,
  UserBulkRegistrationMutationVariables
>
export const UserBulkVerificationDocument = gql`
  mutation userBulkVerification($classroomId: ID!, $users: [UserBulkRegistration!]!) {
    userBulkVerification(classroomId: $classroomId, users: $users) {
      originalName
      firstName
      lastName
      email
      isValid
      errors {
        field
        description
        code
      }
      isAlreadyRegistered
    }
  }
`
export type UserBulkVerificationMutationFn = Apollo.MutationFunction<
  UserBulkVerificationMutation,
  UserBulkVerificationMutationVariables
>

/**
 * __useUserBulkVerificationMutation__
 *
 * To run a mutation, you first call `useUserBulkVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserBulkVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userBulkVerificationMutation, { data, loading, error }] = useUserBulkVerificationMutation({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      users: // value for 'users'
 *   },
 * });
 */
export function useUserBulkVerificationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UserBulkVerificationMutation,
    UserBulkVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UserBulkVerificationMutation, UserBulkVerificationMutationVariables>(
    UserBulkVerificationDocument,
    options
  )
}
export type UserBulkVerificationMutationHookResult = ReturnType<typeof useUserBulkVerificationMutation>
export type UserBulkVerificationMutationResult = Apollo.MutationResult<UserBulkVerificationMutation>
export type UserBulkVerificationMutationOptions = Apollo.BaseMutationOptions<
  UserBulkVerificationMutation,
  UserBulkVerificationMutationVariables
>
export const ConfirmReleaseNotesDocument = gql`
  mutation confirmReleaseNotes($id: ID!) {
    confirmReleaseNotes(id: $id)
  }
`
export type ConfirmReleaseNotesMutationFn = Apollo.MutationFunction<
  ConfirmReleaseNotesMutation,
  ConfirmReleaseNotesMutationVariables
>

/**
 * __useConfirmReleaseNotesMutation__
 *
 * To run a mutation, you first call `useConfirmReleaseNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmReleaseNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmReleaseNotesMutation, { data, loading, error }] = useConfirmReleaseNotesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmReleaseNotesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ConfirmReleaseNotesMutation, ConfirmReleaseNotesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<ConfirmReleaseNotesMutation, ConfirmReleaseNotesMutationVariables>(
    ConfirmReleaseNotesDocument,
    options
  )
}
export type ConfirmReleaseNotesMutationHookResult = ReturnType<typeof useConfirmReleaseNotesMutation>
export type ConfirmReleaseNotesMutationResult = Apollo.MutationResult<ConfirmReleaseNotesMutation>
export type ConfirmReleaseNotesMutationOptions = Apollo.BaseMutationOptions<
  ConfirmReleaseNotesMutation,
  ConfirmReleaseNotesMutationVariables
>
export const GetClassroomsDocument = gql`
  query getClassrooms($getWithStudents: Boolean! = false) {
    classrooms {
      id
      name
      pictureId
      subject
      studentsInClassroom @include(if: $getWithStudents) {
        userProfile {
          id
          firstName
          lastName
          pictureUrl
        }
      }
    }
  }
`

/**
 * __useGetClassroomsQuery__
 *
 * To run a query within a React component, call `useGetClassroomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassroomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassroomsQuery({
 *   variables: {
 *      getWithStudents: // value for 'getWithStudents'
 *   },
 * });
 */
export function useGetClassroomsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetClassroomsQuery, GetClassroomsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetClassroomsQuery, GetClassroomsQueryVariables>(GetClassroomsDocument, options)
}
export function useGetClassroomsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClassroomsQuery, GetClassroomsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetClassroomsQuery, GetClassroomsQueryVariables>(GetClassroomsDocument, options)
}
export type GetClassroomsQueryHookResult = ReturnType<typeof useGetClassroomsQuery>
export type GetClassroomsLazyQueryHookResult = ReturnType<typeof useGetClassroomsLazyQuery>
export type GetClassroomsQueryResult = Apollo.QueryResult<GetClassroomsQuery, GetClassroomsQueryVariables>
export const GetReleaseNotesDocument = gql`
  query getReleaseNotes($input: ReleaseNotesInput!, $page: PageableInput!) {
    releaseNotes(input: $input, page: $page) {
      id
      url
    }
  }
`

/**
 * __useGetReleaseNotesQuery__
 *
 * To run a query within a React component, call `useGetReleaseNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReleaseNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReleaseNotesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetReleaseNotesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>(
    GetReleaseNotesDocument,
    options
  )
}
export function useGetReleaseNotesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>(
    GetReleaseNotesDocument,
    options
  )
}
export type GetReleaseNotesQueryHookResult = ReturnType<typeof useGetReleaseNotesQuery>
export type GetReleaseNotesLazyQueryHookResult = ReturnType<typeof useGetReleaseNotesLazyQuery>
export type GetReleaseNotesQueryResult = Apollo.QueryResult<GetReleaseNotesQuery, GetReleaseNotesQueryVariables>
export const SearchLearningFeatureDocument = gql`
  query searchLearningFeature($searchLearningFeatureInput: SearchLearningFeatureInput!) {
    searchLearningFeature(searchLearningFeatureInput: $searchLearningFeatureInput) {
      id
      images {
        thumbnail
        small
        medium
        large
        default
      }
      origin
      type
      sourceLanguage
      sourceLanguageText
      sourceSoundUrls
      sourceVideoUrls
      targetLanguage
      targetLanguageText
      targetSoundUrls
      targetVideoUrls
      hasSynonyms
      hasAntonyms
      hasDefinitions
    }
  }
`

/**
 * __useSearchLearningFeatureQuery__
 *
 * To run a query within a React component, call `useSearchLearningFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLearningFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLearningFeatureQuery({
 *   variables: {
 *      searchLearningFeatureInput: // value for 'searchLearningFeatureInput'
 *   },
 * });
 */
export function useSearchLearningFeatureQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<SearchLearningFeatureQuery, SearchLearningFeatureQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<SearchLearningFeatureQuery, SearchLearningFeatureQueryVariables>(
    SearchLearningFeatureDocument,
    options
  )
}
export function useSearchLearningFeatureLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchLearningFeatureQuery, SearchLearningFeatureQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<SearchLearningFeatureQuery, SearchLearningFeatureQueryVariables>(
    SearchLearningFeatureDocument,
    options
  )
}
export type SearchLearningFeatureQueryHookResult = ReturnType<typeof useSearchLearningFeatureQuery>
export type SearchLearningFeatureLazyQueryHookResult = ReturnType<typeof useSearchLearningFeatureLazyQuery>
export type SearchLearningFeatureQueryResult = Apollo.QueryResult<
  SearchLearningFeatureQuery,
  SearchLearningFeatureQueryVariables
>
export const SearchDictionaryLearningFeatureDocument = gql`
  query searchDictionaryLearningFeature(
    $searchLearningFeatureInput: SearchDictionaryLearningFeatureInput!
    $page: PageableInput!
    $filter: SearchDictionaryFilterInput
  ) {
    searchDictionaryLearningFeature(
      searchLearningFeatureInput: $searchLearningFeatureInput
      page: $page
      filter: $filter
    ) {
      __typename
      id
      origin
      type
      sourceLanguage
      targetLanguage
      sourceLanguageText
      targetLanguageText
      level
    }
  }
`

/**
 * __useSearchDictionaryLearningFeatureQuery__
 *
 * To run a query within a React component, call `useSearchDictionaryLearningFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDictionaryLearningFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDictionaryLearningFeatureQuery({
 *   variables: {
 *      searchLearningFeatureInput: // value for 'searchLearningFeatureInput'
 *      page: // value for 'page'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchDictionaryLearningFeatureQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SearchDictionaryLearningFeatureQuery,
    SearchDictionaryLearningFeatureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<SearchDictionaryLearningFeatureQuery, SearchDictionaryLearningFeatureQueryVariables>(
    SearchDictionaryLearningFeatureDocument,
    options
  )
}
export function useSearchDictionaryLearningFeatureLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SearchDictionaryLearningFeatureQuery,
    SearchDictionaryLearningFeatureQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<
    SearchDictionaryLearningFeatureQuery,
    SearchDictionaryLearningFeatureQueryVariables
  >(SearchDictionaryLearningFeatureDocument, options)
}
export type SearchDictionaryLearningFeatureQueryHookResult = ReturnType<typeof useSearchDictionaryLearningFeatureQuery>
export type SearchDictionaryLearningFeatureLazyQueryHookResult = ReturnType<
  typeof useSearchDictionaryLearningFeatureLazyQuery
>
export type SearchDictionaryLearningFeatureQueryResult = Apollo.QueryResult<
  SearchDictionaryLearningFeatureQuery,
  SearchDictionaryLearningFeatureQueryVariables
>
export const GetTutorialProgressDocument = gql`
  query getTutorialProgress {
    tutorialProgress {
      tutorialStepCompleted
      isSkipped
    }
  }
`

/**
 * __useGetTutorialProgressQuery__
 *
 * To run a query within a React component, call `useGetTutorialProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTutorialProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTutorialProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTutorialProgressQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetTutorialProgressQuery, GetTutorialProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetTutorialProgressQuery, GetTutorialProgressQueryVariables>(
    GetTutorialProgressDocument,
    options
  )
}
export function useGetTutorialProgressLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTutorialProgressQuery, GetTutorialProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetTutorialProgressQuery, GetTutorialProgressQueryVariables>(
    GetTutorialProgressDocument,
    options
  )
}
export type GetTutorialProgressQueryHookResult = ReturnType<typeof useGetTutorialProgressQuery>
export type GetTutorialProgressLazyQueryHookResult = ReturnType<typeof useGetTutorialProgressLazyQuery>
export type GetTutorialProgressQueryResult = Apollo.QueryResult<
  GetTutorialProgressQuery,
  GetTutorialProgressQueryVariables
>
export const UpdateTutorialProgressDocument = gql`
  mutation updateTutorialProgress($tutorialStepCompleted: Int!, $isSkipped: Boolean!) {
    updateTutorialProgress(tutorialStepCompleted: $tutorialStepCompleted, isSkipped: $isSkipped) {
      isSkipped
      tutorialStepCompleted
    }
  }
`
export type UpdateTutorialProgressMutationFn = Apollo.MutationFunction<
  UpdateTutorialProgressMutation,
  UpdateTutorialProgressMutationVariables
>

/**
 * __useUpdateTutorialProgressMutation__
 *
 * To run a mutation, you first call `useUpdateTutorialProgressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTutorialProgressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTutorialProgressMutation, { data, loading, error }] = useUpdateTutorialProgressMutation({
 *   variables: {
 *      tutorialStepCompleted: // value for 'tutorialStepCompleted'
 *      isSkipped: // value for 'isSkipped'
 *   },
 * });
 */
export function useUpdateTutorialProgressMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTutorialProgressMutation,
    UpdateTutorialProgressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UpdateTutorialProgressMutation, UpdateTutorialProgressMutationVariables>(
    UpdateTutorialProgressDocument,
    options
  )
}
export type UpdateTutorialProgressMutationHookResult = ReturnType<typeof useUpdateTutorialProgressMutation>
export type UpdateTutorialProgressMutationResult = Apollo.MutationResult<UpdateTutorialProgressMutation>
export type UpdateTutorialProgressMutationOptions = Apollo.BaseMutationOptions<
  UpdateTutorialProgressMutation,
  UpdateTutorialProgressMutationVariables
>
export const GetQuizGameDocument = gql`
  query getQuizGame($quizId: ID!) {
    quizGame(quizId: $quizId) {
      id
      name
      code
      gameState
      numOfQuestions
      secondsPerQuestion
      showQuestionInClient
      countdownInSeconds
      currentQuestion {
        id
        index
        assignment {
          __typename @skip(if: true)
          id
          images {
            medium
            large
            default
          }
          soundURL
          videoURL
          language
          text
        }
        assignmentType
        responses {
          __typename @skip(if: true)
          id
          text
        }
        numOfAnswers
        questionResult {
          correctResponseId
          answerDistribution
        }
        hasNext
      }
      connectedPlayers {
        id
        lastName
        firstName
        pictureUrl
        newPosition
        oldPosition
        totalPoints
        newPoints
      }
    }
  }
`

/**
 * __useGetQuizGameQuery__
 *
 * To run a query within a React component, call `useGetQuizGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuizGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuizGameQuery({
 *   variables: {
 *      quizId: // value for 'quizId'
 *   },
 * });
 */
export function useGetQuizGameQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetQuizGameQuery, GetQuizGameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetQuizGameQuery, GetQuizGameQueryVariables>(GetQuizGameDocument, options)
}
export function useGetQuizGameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetQuizGameQuery, GetQuizGameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetQuizGameQuery, GetQuizGameQueryVariables>(GetQuizGameDocument, options)
}
export type GetQuizGameQueryHookResult = ReturnType<typeof useGetQuizGameQuery>
export type GetQuizGameLazyQueryHookResult = ReturnType<typeof useGetQuizGameLazyQuery>
export type GetQuizGameQueryResult = Apollo.QueryResult<GetQuizGameQuery, GetQuizGameQueryVariables>
export const NextQuizQuestionDocument = gql`
  mutation nextQuizQuestion($quizId: ID!) {
    nextQuizQuestion(quizId: $quizId)
  }
`
export type NextQuizQuestionMutationFn = Apollo.MutationFunction<
  NextQuizQuestionMutation,
  NextQuizQuestionMutationVariables
>

/**
 * __useNextQuizQuestionMutation__
 *
 * To run a mutation, you first call `useNextQuizQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNextQuizQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nextQuizQuestionMutation, { data, loading, error }] = useNextQuizQuestionMutation({
 *   variables: {
 *      quizId: // value for 'quizId'
 *   },
 * });
 */
export function useNextQuizQuestionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<NextQuizQuestionMutation, NextQuizQuestionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<NextQuizQuestionMutation, NextQuizQuestionMutationVariables>(
    NextQuizQuestionDocument,
    options
  )
}
export type NextQuizQuestionMutationHookResult = ReturnType<typeof useNextQuizQuestionMutation>
export type NextQuizQuestionMutationResult = Apollo.MutationResult<NextQuizQuestionMutation>
export type NextQuizQuestionMutationOptions = Apollo.BaseMutationOptions<
  NextQuizQuestionMutation,
  NextQuizQuestionMutationVariables
>
export const QuizGameDocument = gql`
  subscription QuizGame($quizId: ID!) {
    quizGame(quizId: $quizId) {
      id
      name
      code
      gameState
      numOfQuestions
      secondsPerQuestion
      showQuestionInClient
      countdownInSeconds
      currentQuestion {
        id
        index
        assignment {
          id
          images {
            medium
            large
            default
          }
          soundURL
          videoURL
          language
          text
        }
        assignmentType
        responses {
          id
          text
        }
        numOfAnswers
        questionResult {
          correctResponseId
          answerDistribution
        }
        hasNext
      }
      connectedPlayers {
        id
        lastName
        firstName
        pictureUrl
        newPosition
        oldPosition
        totalPoints
        newPoints
      }
    }
  }
`

/**
 * __useQuizGameSubscription__
 *
 * To run a query within a React component, call `useQuizGameSubscription` and pass it any options that fit your needs.
 * When your component renders, `useQuizGameSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuizGameSubscription({
 *   variables: {
 *      quizId: // value for 'quizId'
 *   },
 * });
 */
export function useQuizGameSubscription(
  baseOptions: ApolloReactHooks.SubscriptionHookOptions<QuizGameSubscription, QuizGameSubscriptionVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useSubscription<QuizGameSubscription, QuizGameSubscriptionVariables>(
    QuizGameDocument,
    options
  )
}
export type QuizGameSubscriptionHookResult = ReturnType<typeof useQuizGameSubscription>
export type QuizGameSubscriptionResult = Apollo.SubscriptionResult<QuizGameSubscription>
export const StartQuizGameDocument = gql`
  mutation startQuizGame($quizGameInput: QuizGameInput!) {
    startQuizGame(quizGameInput: $quizGameInput) {
      id
      code
    }
  }
`
export type StartQuizGameMutationFn = Apollo.MutationFunction<StartQuizGameMutation, StartQuizGameMutationVariables>

/**
 * __useStartQuizGameMutation__
 *
 * To run a mutation, you first call `useStartQuizGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartQuizGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startQuizGameMutation, { data, loading, error }] = useStartQuizGameMutation({
 *   variables: {
 *      quizGameInput: // value for 'quizGameInput'
 *   },
 * });
 */
export function useStartQuizGameMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<StartQuizGameMutation, StartQuizGameMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<StartQuizGameMutation, StartQuizGameMutationVariables>(
    StartQuizGameDocument,
    options
  )
}
export type StartQuizGameMutationHookResult = ReturnType<typeof useStartQuizGameMutation>
export type StartQuizGameMutationResult = Apollo.MutationResult<StartQuizGameMutation>
export type StartQuizGameMutationOptions = Apollo.BaseMutationOptions<
  StartQuizGameMutation,
  StartQuizGameMutationVariables
>
export const AddUserLearningFeatureDocument = gql`
  mutation addUserLearningFeature($userLearningFeature: UserLearningFeatureInput!) {
    addUserLearningFeature(userLearningFeature: $userLearningFeature) {
      id
      targetLanguageText
      origin
    }
  }
`
export type AddUserLearningFeatureMutationFn = Apollo.MutationFunction<
  AddUserLearningFeatureMutation,
  AddUserLearningFeatureMutationVariables
>

/**
 * __useAddUserLearningFeatureMutation__
 *
 * To run a mutation, you first call `useAddUserLearningFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserLearningFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserLearningFeatureMutation, { data, loading, error }] = useAddUserLearningFeatureMutation({
 *   variables: {
 *      userLearningFeature: // value for 'userLearningFeature'
 *   },
 * });
 */
export function useAddUserLearningFeatureMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddUserLearningFeatureMutation,
    AddUserLearningFeatureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<AddUserLearningFeatureMutation, AddUserLearningFeatureMutationVariables>(
    AddUserLearningFeatureDocument,
    options
  )
}
export type AddUserLearningFeatureMutationHookResult = ReturnType<typeof useAddUserLearningFeatureMutation>
export type AddUserLearningFeatureMutationResult = Apollo.MutationResult<AddUserLearningFeatureMutation>
export type AddUserLearningFeatureMutationOptions = Apollo.BaseMutationOptions<
  AddUserLearningFeatureMutation,
  AddUserLearningFeatureMutationVariables
>
export const CreateLearningActivityDocument = gql`
  mutation createLearningActivity($learningPlanLessonId: ID!, $learningActivityInput: LearningActivityInput!) {
    createLearningActivity(learningPlanLessonId: $learningPlanLessonId, learningActivityInput: $learningActivityInput) {
      id
      learningActivityExecutionId
      previousActivityId
      isInShelf
      name
      state
      tags
      learningActivityType
      estimatedTimeInSeconds
      durations {
        durationInSeconds
        isActive
      }
      skills {
        skill
        isActive
      }
      progress {
        deadline
        progress
        total
        type
      }
      learningFeatureCount
      learningFeatureFilter
    }
  }
`
export type CreateLearningActivityMutationFn = Apollo.MutationFunction<
  CreateLearningActivityMutation,
  CreateLearningActivityMutationVariables
>

/**
 * __useCreateLearningActivityMutation__
 *
 * To run a mutation, you first call `useCreateLearningActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLearningActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLearningActivityMutation, { data, loading, error }] = useCreateLearningActivityMutation({
 *   variables: {
 *      learningPlanLessonId: // value for 'learningPlanLessonId'
 *      learningActivityInput: // value for 'learningActivityInput'
 *   },
 * });
 */
export function useCreateLearningActivityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateLearningActivityMutation,
    CreateLearningActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<CreateLearningActivityMutation, CreateLearningActivityMutationVariables>(
    CreateLearningActivityDocument,
    options
  )
}
export type CreateLearningActivityMutationHookResult = ReturnType<typeof useCreateLearningActivityMutation>
export type CreateLearningActivityMutationResult = Apollo.MutationResult<CreateLearningActivityMutation>
export type CreateLearningActivityMutationOptions = Apollo.BaseMutationOptions<
  CreateLearningActivityMutation,
  CreateLearningActivityMutationVariables
>
export const CreateLearningPlanLessonDocument = gql`
  mutation createLearningPlanLesson($classroomId: ID!, $learningPlanLessonInput: LearningPlanLessonInput!) {
    createLearningPlanLesson(classroomId: $classroomId, learningPlanLessonInput: $learningPlanLessonInput) {
      id
      isCurrent
      order1
      previousLessonId
      level1Name
      level2Name
      isUserOwned
      description
      content {
        id
        targetLanguageText
        sourceLanguageText
        origin
      }
      userContent {
        id
        targetLanguageText
        sourceLanguageText
        origin
      }
      books {
        id
        label
        image {
          default
          small
        }
        sourceUrl
        currentPage
      }
      learningActivities {
        id
        learningActivityExecutionId
        previousActivityId
        isInShelf
        name
        state
        tags
        learningActivityType
        estimatedTimeInSeconds
        durations {
          durationInSeconds
          isActive
        }
        skills {
          skill
          isActive
        }
        progress {
          deadline
          progress
          total
          type
        }
        learningFeatureCount
        learningFeatureFilter
      }
    }
  }
`
export type CreateLearningPlanLessonMutationFn = Apollo.MutationFunction<
  CreateLearningPlanLessonMutation,
  CreateLearningPlanLessonMutationVariables
>

/**
 * __useCreateLearningPlanLessonMutation__
 *
 * To run a mutation, you first call `useCreateLearningPlanLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLearningPlanLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLearningPlanLessonMutation, { data, loading, error }] = useCreateLearningPlanLessonMutation({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      learningPlanLessonInput: // value for 'learningPlanLessonInput'
 *   },
 * });
 */
export function useCreateLearningPlanLessonMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateLearningPlanLessonMutation,
    CreateLearningPlanLessonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<CreateLearningPlanLessonMutation, CreateLearningPlanLessonMutationVariables>(
    CreateLearningPlanLessonDocument,
    options
  )
}
export type CreateLearningPlanLessonMutationHookResult = ReturnType<typeof useCreateLearningPlanLessonMutation>
export type CreateLearningPlanLessonMutationResult = Apollo.MutationResult<CreateLearningPlanLessonMutation>
export type CreateLearningPlanLessonMutationOptions = Apollo.BaseMutationOptions<
  CreateLearningPlanLessonMutation,
  CreateLearningPlanLessonMutationVariables
>
export const DeleteLearningActivityDocument = gql`
  mutation deleteLearningActivity($learningActivityId: ID!, $learningActivitiesOrder: [ID!]!) {
    deleteLearningActivity(learningActivityId: $learningActivityId, learningActivitiesOrder: $learningActivitiesOrder)
  }
`
export type DeleteLearningActivityMutationFn = Apollo.MutationFunction<
  DeleteLearningActivityMutation,
  DeleteLearningActivityMutationVariables
>

/**
 * __useDeleteLearningActivityMutation__
 *
 * To run a mutation, you first call `useDeleteLearningActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLearningActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLearningActivityMutation, { data, loading, error }] = useDeleteLearningActivityMutation({
 *   variables: {
 *      learningActivityId: // value for 'learningActivityId'
 *      learningActivitiesOrder: // value for 'learningActivitiesOrder'
 *   },
 * });
 */
export function useDeleteLearningActivityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteLearningActivityMutation,
    DeleteLearningActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<DeleteLearningActivityMutation, DeleteLearningActivityMutationVariables>(
    DeleteLearningActivityDocument,
    options
  )
}
export type DeleteLearningActivityMutationHookResult = ReturnType<typeof useDeleteLearningActivityMutation>
export type DeleteLearningActivityMutationResult = Apollo.MutationResult<DeleteLearningActivityMutation>
export type DeleteLearningActivityMutationOptions = Apollo.BaseMutationOptions<
  DeleteLearningActivityMutation,
  DeleteLearningActivityMutationVariables
>
export const DeleteLearningPlanLessonDocument = gql`
  mutation deleteLearningPlanLesson($learningPlanLessonId: ID!) {
    deleteLearningPlanLesson(learningPlanLessonId: $learningPlanLessonId)
  }
`
export type DeleteLearningPlanLessonMutationFn = Apollo.MutationFunction<
  DeleteLearningPlanLessonMutation,
  DeleteLearningPlanLessonMutationVariables
>

/**
 * __useDeleteLearningPlanLessonMutation__
 *
 * To run a mutation, you first call `useDeleteLearningPlanLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLearningPlanLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLearningPlanLessonMutation, { data, loading, error }] = useDeleteLearningPlanLessonMutation({
 *   variables: {
 *      learningPlanLessonId: // value for 'learningPlanLessonId'
 *   },
 * });
 */
export function useDeleteLearningPlanLessonMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteLearningPlanLessonMutation,
    DeleteLearningPlanLessonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<DeleteLearningPlanLessonMutation, DeleteLearningPlanLessonMutationVariables>(
    DeleteLearningPlanLessonDocument,
    options
  )
}
export type DeleteLearningPlanLessonMutationHookResult = ReturnType<typeof useDeleteLearningPlanLessonMutation>
export type DeleteLearningPlanLessonMutationResult = Apollo.MutationResult<DeleteLearningPlanLessonMutation>
export type DeleteLearningPlanLessonMutationOptions = Apollo.BaseMutationOptions<
  DeleteLearningPlanLessonMutation,
  DeleteLearningPlanLessonMutationVariables
>
export const DeleteUserLearningFeatureDocument = gql`
  mutation deleteUserLearningFeature($learningFeatureId: ID!) {
    deleteUserLearningFeature(learningFeatureId: $learningFeatureId)
  }
`
export type DeleteUserLearningFeatureMutationFn = Apollo.MutationFunction<
  DeleteUserLearningFeatureMutation,
  DeleteUserLearningFeatureMutationVariables
>

/**
 * __useDeleteUserLearningFeatureMutation__
 *
 * To run a mutation, you first call `useDeleteUserLearningFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserLearningFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserLearningFeatureMutation, { data, loading, error }] = useDeleteUserLearningFeatureMutation({
 *   variables: {
 *      learningFeatureId: // value for 'learningFeatureId'
 *   },
 * });
 */
export function useDeleteUserLearningFeatureMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserLearningFeatureMutation,
    DeleteUserLearningFeatureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<DeleteUserLearningFeatureMutation, DeleteUserLearningFeatureMutationVariables>(
    DeleteUserLearningFeatureDocument,
    options
  )
}
export type DeleteUserLearningFeatureMutationHookResult = ReturnType<typeof useDeleteUserLearningFeatureMutation>
export type DeleteUserLearningFeatureMutationResult = Apollo.MutationResult<DeleteUserLearningFeatureMutation>
export type DeleteUserLearningFeatureMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserLearningFeatureMutation,
  DeleteUserLearningFeatureMutationVariables
>
export const GetBooksDocument = gql`
  query getBooks($subject: Subject!) {
    books(subject: $subject) {
      id
      name
      type
      sourceLanguage
      image {
        default
        small
        medium
      }
    }
  }
`

/**
 * __useGetBooksQuery__
 *
 * To run a query within a React component, call `useGetBooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBooksQuery({
 *   variables: {
 *      subject: // value for 'subject'
 *   },
 * });
 */
export function useGetBooksQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetBooksQuery, GetBooksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetBooksQuery, GetBooksQueryVariables>(GetBooksDocument, options)
}
export function useGetBooksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBooksQuery, GetBooksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetBooksQuery, GetBooksQueryVariables>(GetBooksDocument, options)
}
export type GetBooksQueryHookResult = ReturnType<typeof useGetBooksQuery>
export type GetBooksLazyQueryHookResult = ReturnType<typeof useGetBooksLazyQuery>
export type GetBooksQueryResult = Apollo.QueryResult<GetBooksQuery, GetBooksQueryVariables>
export const GetLearningActivityDocument = gql`
  query getLearningActivity($learningActivityId: ID!) {
    learningActivity(learningActivityId: $learningActivityId) {
      id
      learningActivityExecutionId
      previousActivityId
      isInShelf
      name
      state
      tags
      learningActivityType
      estimatedTimeInSeconds
      durations {
        durationInSeconds
        isActive
      }
      skills {
        skill
        isActive
      }
      progress {
        deadline
        progress
        total
        type
      }
      learningFeatureCount
      learningFeatureFilter
      learningFeatures {
        id
        images {
          default
          small
        }
        type
        sourceLanguage
        sourceLanguageText
        targetLanguage
        targetLanguageText
        sourceSoundUrls
        targetSoundUrls
        sourceVideoUrls
        targetVideoUrls
        hasSynonyms
        hasAntonyms
        hasDefinitions
      }
      gameProperties {
        questionCount
        coverStyle
        isSoundEnabled
        secondsPerQuestion
        showQuestionInClient
        title
        worksheetType
        worksheetLexicalType
      }
    }
  }
`

/**
 * __useGetLearningActivityQuery__
 *
 * To run a query within a React component, call `useGetLearningActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningActivityQuery({
 *   variables: {
 *      learningActivityId: // value for 'learningActivityId'
 *   },
 * });
 */
export function useGetLearningActivityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetLearningActivityQuery, GetLearningActivityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetLearningActivityQuery, GetLearningActivityQueryVariables>(
    GetLearningActivityDocument,
    options
  )
}
export function useGetLearningActivityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLearningActivityQuery, GetLearningActivityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetLearningActivityQuery, GetLearningActivityQueryVariables>(
    GetLearningActivityDocument,
    options
  )
}
export type GetLearningActivityQueryHookResult = ReturnType<typeof useGetLearningActivityQuery>
export type GetLearningActivityLazyQueryHookResult = ReturnType<typeof useGetLearningActivityLazyQuery>
export type GetLearningActivityQueryResult = Apollo.QueryResult<
  GetLearningActivityQuery,
  GetLearningActivityQueryVariables
>
export const GetLearningActivityResultsDocument = gql`
  query getLearningActivityResults($learningActivityId: ID!) {
    learningActivityResults(learningActivityId: $learningActivityId) {
      learningActivityId
      timestamp
      participantResults {
        learningActivityId
        interactions
        totalAnswers
        correctAnswers
        successMeasure
        progress
        points
        interventionNeeded
        answers {
          flashCardId
          flashCardAssignmentType
          flashCardResponseType
          flashCardFullScreenType
          skipCount
          tryCount
          points
          userAnswers {
            assignmentText
            userAnswer
            correctAnswer
            result
          }
        }
        participant {
          ... on Student {
            userProfile {
              id
              pictureUrl
              firstName
              lastName
            }
          }
          ... on Team {
            name
            imageURL
          }
        }
      }
      frequentMistakes {
        learningFeature {
          id
          targetLanguageText
        }
        mistakeCount
      }
      skillResults {
        skill
        successMeasure
      }
    }
  }
`

/**
 * __useGetLearningActivityResultsQuery__
 *
 * To run a query within a React component, call `useGetLearningActivityResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningActivityResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningActivityResultsQuery({
 *   variables: {
 *      learningActivityId: // value for 'learningActivityId'
 *   },
 * });
 */
export function useGetLearningActivityResultsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetLearningActivityResultsQuery,
    GetLearningActivityResultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetLearningActivityResultsQuery, GetLearningActivityResultsQueryVariables>(
    GetLearningActivityResultsDocument,
    options
  )
}
export function useGetLearningActivityResultsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetLearningActivityResultsQuery,
    GetLearningActivityResultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetLearningActivityResultsQuery, GetLearningActivityResultsQueryVariables>(
    GetLearningActivityResultsDocument,
    options
  )
}
export type GetLearningActivityResultsQueryHookResult = ReturnType<typeof useGetLearningActivityResultsQuery>
export type GetLearningActivityResultsLazyQueryHookResult = ReturnType<typeof useGetLearningActivityResultsLazyQuery>
export type GetLearningActivityResultsQueryResult = Apollo.QueryResult<
  GetLearningActivityResultsQuery,
  GetLearningActivityResultsQueryVariables
>
export const GetLearningPlanContentDocument = gql`
  query getLearningPlanContent($classroomId: ID!, $learningPlanLessonId: ID!) {
    learningPlanLessons(classroomId: $classroomId, learningPlanLessonIds: [$learningPlanLessonId]) {
      content {
        id
        images {
          default
          small
        }
        type
        origin
        sourceLanguage
        sourceLanguageText
        targetLanguage
        targetLanguageText
        targetSoundUrls
        sourceSoundUrls
        sourceVideoUrls
        targetVideoUrls
        hasSynonyms
        hasAntonyms
        hasDefinitions
      }
      userContent {
        id
        images {
          default
          small
        }
        type
        origin
        sourceLanguage
        sourceLanguageText
        targetLanguage
        targetLanguageText
        targetSoundUrls
        sourceSoundUrls
        sourceVideoUrls
        targetVideoUrls
        hasSynonyms
        hasAntonyms
        hasDefinitions
      }
    }
  }
`

/**
 * __useGetLearningPlanContentQuery__
 *
 * To run a query within a React component, call `useGetLearningPlanContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningPlanContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningPlanContentQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      learningPlanLessonId: // value for 'learningPlanLessonId'
 *   },
 * });
 */
export function useGetLearningPlanContentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetLearningPlanContentQuery, GetLearningPlanContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetLearningPlanContentQuery, GetLearningPlanContentQueryVariables>(
    GetLearningPlanContentDocument,
    options
  )
}
export function useGetLearningPlanContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLearningPlanContentQuery, GetLearningPlanContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetLearningPlanContentQuery, GetLearningPlanContentQueryVariables>(
    GetLearningPlanContentDocument,
    options
  )
}
export type GetLearningPlanContentQueryHookResult = ReturnType<typeof useGetLearningPlanContentQuery>
export type GetLearningPlanContentLazyQueryHookResult = ReturnType<typeof useGetLearningPlanContentLazyQuery>
export type GetLearningPlanContentQueryResult = Apollo.QueryResult<
  GetLearningPlanContentQuery,
  GetLearningPlanContentQueryVariables
>
export const GetLearningPlanLessonsDocument = gql`
  query getLearningPlanLessons(
    $classroomId: ID!
    $withDetail: Boolean!
    $learningPlanLessonIds: [ID!]
    $withLearningFeatures: Boolean!
  ) {
    learningPlanLessons(
      classroomId: $classroomId
      withDetail: $withDetail
      withLearningFeatures: $withLearningFeatures
      learningPlanLessonIds: $learningPlanLessonIds
    ) {
      id
      isCurrent
      order1
      level1Name
      level2Name
      isUserOwned
      previousLessonId
      ...LearningPlanDetails @include(if: $withDetail)
    }
  }
  ${LearningPlanDetailsFragmentDoc}
`

/**
 * __useGetLearningPlanLessonsQuery__
 *
 * To run a query within a React component, call `useGetLearningPlanLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningPlanLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningPlanLessonsQuery({
 *   variables: {
 *      classroomId: // value for 'classroomId'
 *      withDetail: // value for 'withDetail'
 *      learningPlanLessonIds: // value for 'learningPlanLessonIds'
 *      withLearningFeatures: // value for 'withLearningFeatures'
 *   },
 * });
 */
export function useGetLearningPlanLessonsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetLearningPlanLessonsQuery, GetLearningPlanLessonsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetLearningPlanLessonsQuery, GetLearningPlanLessonsQueryVariables>(
    GetLearningPlanLessonsDocument,
    options
  )
}
export function useGetLearningPlanLessonsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLearningPlanLessonsQuery, GetLearningPlanLessonsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetLearningPlanLessonsQuery, GetLearningPlanLessonsQueryVariables>(
    GetLearningPlanLessonsDocument,
    options
  )
}
export type GetLearningPlanLessonsQueryHookResult = ReturnType<typeof useGetLearningPlanLessonsQuery>
export type GetLearningPlanLessonsLazyQueryHookResult = ReturnType<typeof useGetLearningPlanLessonsLazyQuery>
export type GetLearningPlanLessonsQueryResult = Apollo.QueryResult<
  GetLearningPlanLessonsQuery,
  GetLearningPlanLessonsQueryVariables
>
export const GetSubjectsDocument = gql`
  query getSubjects {
    subjects
  }
`

/**
 * __useGetSubjectsQuery__
 *
 * To run a query within a React component, call `useGetSubjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubjectsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubjectsQuery, GetSubjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetSubjectsQuery, GetSubjectsQueryVariables>(GetSubjectsDocument, options)
}
export function useGetSubjectsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubjectsQuery, GetSubjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetSubjectsQuery, GetSubjectsQueryVariables>(GetSubjectsDocument, options)
}
export type GetSubjectsQueryHookResult = ReturnType<typeof useGetSubjectsQuery>
export type GetSubjectsLazyQueryHookResult = ReturnType<typeof useGetSubjectsLazyQuery>
export type GetSubjectsQueryResult = Apollo.QueryResult<GetSubjectsQuery, GetSubjectsQueryVariables>
export const GetWorksheetsAllowedTypesDocument = gql`
  query getWorksheetsAllowedTypes {
    worksheetsAllowedTypes {
      worksheetType
      allowedLexicalTypes
    }
  }
`

/**
 * __useGetWorksheetsAllowedTypesQuery__
 *
 * To run a query within a React component, call `useGetWorksheetsAllowedTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorksheetsAllowedTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorksheetsAllowedTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorksheetsAllowedTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetWorksheetsAllowedTypesQuery,
    GetWorksheetsAllowedTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetWorksheetsAllowedTypesQuery, GetWorksheetsAllowedTypesQueryVariables>(
    GetWorksheetsAllowedTypesDocument,
    options
  )
}
export function useGetWorksheetsAllowedTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetWorksheetsAllowedTypesQuery,
    GetWorksheetsAllowedTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetWorksheetsAllowedTypesQuery, GetWorksheetsAllowedTypesQueryVariables>(
    GetWorksheetsAllowedTypesDocument,
    options
  )
}
export type GetWorksheetsAllowedTypesQueryHookResult = ReturnType<typeof useGetWorksheetsAllowedTypesQuery>
export type GetWorksheetsAllowedTypesLazyQueryHookResult = ReturnType<typeof useGetWorksheetsAllowedTypesLazyQuery>
export type GetWorksheetsAllowedTypesQueryResult = Apollo.QueryResult<
  GetWorksheetsAllowedTypesQuery,
  GetWorksheetsAllowedTypesQueryVariables
>
export const StartLearningActivityDocument = gql`
  mutation startLearningActivity($learningActivityId: ID!) {
    startLearningActivity(learningActivityId: $learningActivityId) {
      id
      state
      estimatedTimeInSeconds
      learningActivityType
      name
      progress {
        deadline
        progress
        total
        type
      }
      learningActivityExecutionId
      previousActivityId
      isInShelf
      learningFeatureCount
      learningFeatureFilter
      learningFeatures {
        id
        images {
          default
          medium
        }
        type
        sourceLanguage
        sourceLanguageText
        sourceSoundUrls
        targetLanguage
        targetLanguageText
        targetSoundUrls
      }
      gameProperties {
        questionCount
        coverStyle
        isSoundEnabled
        secondsPerQuestion
        showQuestionInClient
        title
        worksheetType
        worksheetLexicalType
      }
    }
  }
`
export type StartLearningActivityMutationFn = Apollo.MutationFunction<
  StartLearningActivityMutation,
  StartLearningActivityMutationVariables
>

/**
 * __useStartLearningActivityMutation__
 *
 * To run a mutation, you first call `useStartLearningActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartLearningActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startLearningActivityMutation, { data, loading, error }] = useStartLearningActivityMutation({
 *   variables: {
 *      learningActivityId: // value for 'learningActivityId'
 *   },
 * });
 */
export function useStartLearningActivityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StartLearningActivityMutation,
    StartLearningActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<StartLearningActivityMutation, StartLearningActivityMutationVariables>(
    StartLearningActivityDocument,
    options
  )
}
export type StartLearningActivityMutationHookResult = ReturnType<typeof useStartLearningActivityMutation>
export type StartLearningActivityMutationResult = Apollo.MutationResult<StartLearningActivityMutation>
export type StartLearningActivityMutationOptions = Apollo.BaseMutationOptions<
  StartLearningActivityMutation,
  StartLearningActivityMutationVariables
>
export const StopLearningActivityDocument = gql`
  mutation stopLearningActivity($learningActivityId: ID!) {
    stopLearningActivity(learningActivityId: $learningActivityId) {
      id
      state
      progress {
        deadline
        progress
        total
        type
      }
      learningActivityExecutionId
      previousActivityId
      isInShelf
    }
  }
`
export type StopLearningActivityMutationFn = Apollo.MutationFunction<
  StopLearningActivityMutation,
  StopLearningActivityMutationVariables
>

/**
 * __useStopLearningActivityMutation__
 *
 * To run a mutation, you first call `useStopLearningActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopLearningActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopLearningActivityMutation, { data, loading, error }] = useStopLearningActivityMutation({
 *   variables: {
 *      learningActivityId: // value for 'learningActivityId'
 *   },
 * });
 */
export function useStopLearningActivityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    StopLearningActivityMutation,
    StopLearningActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<StopLearningActivityMutation, StopLearningActivityMutationVariables>(
    StopLearningActivityDocument,
    options
  )
}
export type StopLearningActivityMutationHookResult = ReturnType<typeof useStopLearningActivityMutation>
export type StopLearningActivityMutationResult = Apollo.MutationResult<StopLearningActivityMutation>
export type StopLearningActivityMutationOptions = Apollo.BaseMutationOptions<
  StopLearningActivityMutation,
  StopLearningActivityMutationVariables
>
export const UpdateLearningActivityDocument = gql`
  mutation updateLearningActivity($learningActivityId: ID!, $learningActivityInput: LearningActivityInput!) {
    updateLearningActivity(learningActivityId: $learningActivityId, learningActivityInput: $learningActivityInput) {
      id
      learningActivityExecutionId
      previousActivityId
      isInShelf
      name
      state
      tags
      learningActivityType
      estimatedTimeInSeconds
      durations {
        durationInSeconds
        isActive
      }
      skills {
        skill
        isActive
      }
      progress {
        deadline
        progress
        total
        type
      }
      learningFeatureCount
      learningFeatureFilter
      learningFeatures {
        id
        images {
          default
          small
        }
        type
        sourceLanguage
        sourceLanguageText
        targetLanguage
        targetLanguageText
        sourceSoundUrls
        targetSoundUrls
        sourceVideoUrls
        targetVideoUrls
      }
      gameProperties {
        questionCount
        coverStyle
        isSoundEnabled
        secondsPerQuestion
        showQuestionInClient
        title
        worksheetType
        worksheetLexicalType
      }
    }
  }
`
export type UpdateLearningActivityMutationFn = Apollo.MutationFunction<
  UpdateLearningActivityMutation,
  UpdateLearningActivityMutationVariables
>

/**
 * __useUpdateLearningActivityMutation__
 *
 * To run a mutation, you first call `useUpdateLearningActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLearningActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLearningActivityMutation, { data, loading, error }] = useUpdateLearningActivityMutation({
 *   variables: {
 *      learningActivityId: // value for 'learningActivityId'
 *      learningActivityInput: // value for 'learningActivityInput'
 *   },
 * });
 */
export function useUpdateLearningActivityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLearningActivityMutation,
    UpdateLearningActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UpdateLearningActivityMutation, UpdateLearningActivityMutationVariables>(
    UpdateLearningActivityDocument,
    options
  )
}
export type UpdateLearningActivityMutationHookResult = ReturnType<typeof useUpdateLearningActivityMutation>
export type UpdateLearningActivityMutationResult = Apollo.MutationResult<UpdateLearningActivityMutation>
export type UpdateLearningActivityMutationOptions = Apollo.BaseMutationOptions<
  UpdateLearningActivityMutation,
  UpdateLearningActivityMutationVariables
>
export const UpdateLearningPlanLessonDocument = gql`
  mutation updateLearningPlanLesson($learningPlanLessonId: ID!, $learningPlanLessonInput: LearningPlanLessonInput!) {
    updateLearningPlanLesson(
      learningPlanLessonId: $learningPlanLessonId
      learningPlanLessonInput: $learningPlanLessonInput
    ) {
      id
      level1Name
      level2Name
      isUserOwned
      content {
        id
        targetLanguageText
        sourceLanguageText
        origin
      }
      userContent {
        id
        targetLanguageText
        sourceLanguageText
        origin
      }
    }
  }
`
export type UpdateLearningPlanLessonMutationFn = Apollo.MutationFunction<
  UpdateLearningPlanLessonMutation,
  UpdateLearningPlanLessonMutationVariables
>

/**
 * __useUpdateLearningPlanLessonMutation__
 *
 * To run a mutation, you first call `useUpdateLearningPlanLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLearningPlanLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLearningPlanLessonMutation, { data, loading, error }] = useUpdateLearningPlanLessonMutation({
 *   variables: {
 *      learningPlanLessonId: // value for 'learningPlanLessonId'
 *      learningPlanLessonInput: // value for 'learningPlanLessonInput'
 *   },
 * });
 */
export function useUpdateLearningPlanLessonMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLearningPlanLessonMutation,
    UpdateLearningPlanLessonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UpdateLearningPlanLessonMutation, UpdateLearningPlanLessonMutationVariables>(
    UpdateLearningPlanLessonDocument,
    options
  )
}
export type UpdateLearningPlanLessonMutationHookResult = ReturnType<typeof useUpdateLearningPlanLessonMutation>
export type UpdateLearningPlanLessonMutationResult = Apollo.MutationResult<UpdateLearningPlanLessonMutation>
export type UpdateLearningPlanLessonMutationOptions = Apollo.BaseMutationOptions<
  UpdateLearningPlanLessonMutation,
  UpdateLearningPlanLessonMutationVariables
>
export const UpdateUserLearningFeatureDocument = gql`
  mutation updateUserLearningFeature($learningFeatureId: ID!, $userLearningFeature: UserLearningFeatureInput!) {
    updateUserLearningFeature(learningFeatureId: $learningFeatureId, userLearningFeature: $userLearningFeature) {
      __typename
      id
      sourceLanguage
      sourceLanguageText
      targetLanguage
      targetLanguageText
      level
      type
      origin
    }
  }
`
export type UpdateUserLearningFeatureMutationFn = Apollo.MutationFunction<
  UpdateUserLearningFeatureMutation,
  UpdateUserLearningFeatureMutationVariables
>

/**
 * __useUpdateUserLearningFeatureMutation__
 *
 * To run a mutation, you first call `useUpdateUserLearningFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLearningFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLearningFeatureMutation, { data, loading, error }] = useUpdateUserLearningFeatureMutation({
 *   variables: {
 *      learningFeatureId: // value for 'learningFeatureId'
 *      userLearningFeature: // value for 'userLearningFeature'
 *   },
 * });
 */
export function useUpdateUserLearningFeatureMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserLearningFeatureMutation,
    UpdateUserLearningFeatureMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UpdateUserLearningFeatureMutation, UpdateUserLearningFeatureMutationVariables>(
    UpdateUserLearningFeatureDocument,
    options
  )
}
export type UpdateUserLearningFeatureMutationHookResult = ReturnType<typeof useUpdateUserLearningFeatureMutation>
export type UpdateUserLearningFeatureMutationResult = Apollo.MutationResult<UpdateUserLearningFeatureMutation>
export type UpdateUserLearningFeatureMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserLearningFeatureMutation,
  UpdateUserLearningFeatureMutationVariables
>
export const GetWorksheetsForActivityDocument = gql`
  query getWorksheetsForActivity($learningActivityId: ID!) {
    worksheetsForActivity(learningActivityId: $learningActivityId) {
      fileName
      worksheetType
      worksheetLexicalType
      contentBase64
    }
  }
`

/**
 * __useGetWorksheetsForActivityQuery__
 *
 * To run a query within a React component, call `useGetWorksheetsForActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorksheetsForActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorksheetsForActivityQuery({
 *   variables: {
 *      learningActivityId: // value for 'learningActivityId'
 *   },
 * });
 */
export function useGetWorksheetsForActivityQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetWorksheetsForActivityQuery, GetWorksheetsForActivityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetWorksheetsForActivityQuery, GetWorksheetsForActivityQueryVariables>(
    GetWorksheetsForActivityDocument,
    options
  )
}
export function useGetWorksheetsForActivityLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetWorksheetsForActivityQuery,
    GetWorksheetsForActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetWorksheetsForActivityQuery, GetWorksheetsForActivityQueryVariables>(
    GetWorksheetsForActivityDocument,
    options
  )
}
export type GetWorksheetsForActivityQueryHookResult = ReturnType<typeof useGetWorksheetsForActivityQuery>
export type GetWorksheetsForActivityLazyQueryHookResult = ReturnType<typeof useGetWorksheetsForActivityLazyQuery>
export type GetWorksheetsForActivityQueryResult = Apollo.QueryResult<
  GetWorksheetsForActivityQuery,
  GetWorksheetsForActivityQueryVariables
>
export const AddUserRoleDocument = gql`
  mutation addUserRole($userRole: UserRole!) {
    addUserRole(userRole: $userRole)
  }
`
export type AddUserRoleMutationFn = Apollo.MutationFunction<AddUserRoleMutation, AddUserRoleMutationVariables>

/**
 * __useAddUserRoleMutation__
 *
 * To run a mutation, you first call `useAddUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserRoleMutation, { data, loading, error }] = useAddUserRoleMutation({
 *   variables: {
 *      userRole: // value for 'userRole'
 *   },
 * });
 */
export function useAddUserRoleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddUserRoleMutation, AddUserRoleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<AddUserRoleMutation, AddUserRoleMutationVariables>(AddUserRoleDocument, options)
}
export type AddUserRoleMutationHookResult = ReturnType<typeof useAddUserRoleMutation>
export type AddUserRoleMutationResult = Apollo.MutationResult<AddUserRoleMutation>
export type AddUserRoleMutationOptions = Apollo.BaseMutationOptions<AddUserRoleMutation, AddUserRoleMutationVariables>
export const GetUserProfileDocument = gql`
  query getUserProfile {
    userProfile {
      id
      pictureUrl
      firstName
      lastName
      username
      birthday
      email
    }
  }
`

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options)
}
export function useGetUserProfileLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options
  )
}
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>
export const UpdateUserProfileDocument = gql`
  mutation updateUserProfile($userProfile: UserProfileInput) {
    updateUserProfile(userProfile: $userProfile) {
      id
      firstName
      lastName
      birthday
      pictureUrl
    }
  }
`
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      userProfile: // value for 'userProfile'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return ApolloReactHooks.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(
    UpdateUserProfileDocument,
    options
  )
}
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>
