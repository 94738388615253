import { SocialGameTypeSorted } from '@app/modules/common/model/enums'

export const Pages = {
  ROOT: '/',
  LOGIN: '/login',
  SETTINGS: '/settings',

  // Games
  GAMES: '/games',
  GAMES_TYPE: (type: SocialGameTypeSorted) => `/games/${type}`,

  // Dictionary
  DICTIONARY: (language: string) => `/dictionary/${language}`,

  // Classroom
  CLASSROOMS: '/classrooms',
  NEW_CLASS: '/classrooms/new-class',
  CLASSROOM_DETAIL: (id: string) => `/classrooms/${id}`,
  CLASSROOM_SETTINGS: (id: string) => `/classrooms/${id}/preferences`,
  CLASSROOM_SETTINGS_STUDENT: (id: string, studentId: string) => `/classrooms/${id}/preferences/student/${studentId}`,
  CLASSROOM_DETAIL_STUDENT: (id: string, studentId: string) => `/classrooms/${id}/students/${studentId}`,
  CLASSROOM_EXECUTION_DETAIL: (id: string, executionId: string) => `/classrooms/${id}/executions/${executionId}`,
  CLASSROOM_ASSIGNMENT_DETAIL: (id: string, learningAssignmentId: string) =>
    `/classrooms/${id}/learning-assignment/${learningAssignmentId}`,

  MAINTENANCE: '/maintenance'
} as const
