import { Tool01 } from '@untitled-ui/icons-react'

import { Heading } from '@app/components'

export const MaintenancePage = () => {
  return (
    <div className="absolute h-screen w-full overscroll-none bg-white">
      <Heading title="Maintenance" className="!text-black">
        Maintenance
      </Heading>
      <div className="flex h-screen w-screen bg-white">
        <div className="my-auto flex w-full flex-col items-center justify-center">
          <Heading size="m" className="flex items-center gap-2">
            <Tool01 /> We&apos;ll be back soon...
          </Heading>
          <img
            src="https://www.ontariovirtualschool.ca/scheduled_maintenance.gif"
            alt="Maintenance"
            className="mx-auto my-auto w-1/2"
          />
        </div>
      </div>
    </div>
  )
}
